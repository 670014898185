import React from "react";
import PropTypes from "prop-types";
import { Drawer } from "../../../components/Drawer";
import { Div, Row, Text } from "../../../styles/Common";
import { theme } from "../../../styles/theme";
import { ChevronRightIcon, CloseIcon, LogoutIcon } from "../../../assets/icons";
import { Link } from "../../../components/Link";

export const DrawerMobile = ({
  showDrawer,
  handleCloseDrawer,
  setActiveKey,
  initials,
  name,
  lastName,
  handleCloseSesion,
  setEditMyAccount,
}) => {
  const menu = [
    {
      title: "Inicio",
      onClick: () => {
        setActiveKey("1");
        setEditMyAccount(false);
        handleCloseDrawer();
      },
    },
    {
      title: "Mi cuenta",
      onClick: () => {
        setActiveKey("2");
        setEditMyAccount(false);
        handleCloseDrawer();
      },
    },
  ];

  return (
    <Drawer
      closeIcon={<CloseIcon stroke={theme.colors.white} />}
      open={showDrawer}
      onClose={handleCloseDrawer}
      width="300px"
      placement="left"
      title={
        <Text
          weight={theme.fonts.weight.semibold}
          size={theme.fonts.size.default}
          color={theme.colors.white}
        >
          Menu
        </Text>
      }
    >
      <Div direction="column" height="100%">
        <Text
          weight={theme.fonts.weight.bold}
          size={theme.fonts.size.h4}
          color={theme.colors.white}
          mb="60px"
        >
          {`Hola ${name}`}
        </Text>
        {menu.map((item, index) => (
          <Row
            key={index}
            height="46px"
            p="0 0 22px 0"
            m="0 0 20px 0"
            justify="space-between"
            style={{ borderBottom: `1px solid ${theme.colors.gray200}` }}
            onClick={item.onClick}
          >
            <Text size={theme.fonts.size.h6} color={theme.colors.white}>
              {item.title}
            </Text>
            <ChevronRightIcon stroke={theme.colors.white} />
          </Row>
        ))}
        <Div
          align="center"
          radius="17px"
          p="11px 14px"
          background={theme.colors.blackBlur1}
          style={{
            border: `1px solid ${theme.colors.white}`,
            marginTop: "auto",
          }}
        >
          <Div
            height="44px"
            width="44px"
            style={{ minWidth: "44px" }}
            background={theme.colors.red}
            radius="50px"
            align="center"
            justify="center"
            m="0 16px 0 0"
          >
            <Text
              color={theme.colors.white}
              weight={theme.fonts.weight.semibold}
              size={theme.fonts.size.h6}
            >
              {initials}
            </Text>
          </Div>
          <Text
            color={theme.colors.white}
            align="left"
            style={{ width: "100%" }}
          >{`${name} ${lastName}`}</Text>
          <Link onClick={handleCloseSesion} style={{ alingSelf: "end" }}>
            <LogoutIcon stroke={theme.colors.white} />
          </Link>
        </Div>
      </Div>
    </Drawer>
  );
};

DrawerMobile.propTypes = {
  showDrawer: PropTypes.bool,
  handleCloseDrawer: PropTypes.func,
  setActiveKey: PropTypes.func,
  initials: PropTypes.string,
  name: PropTypes.string,
  lastName: PropTypes.string,
  handleCloseSesion: PropTypes.func,
  setEditMyAccount: PropTypes.func,
};
