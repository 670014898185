import styled, { css } from "styled-components";
import { Select } from "antd";
import { theme } from "../../styles/theme";

export const CustomSelect = styled(Select)`
  font-family: ${theme.fonts.family};
  font-size: ${theme.fonts.size.default};
  color: ${(props) => props.color || theme.colors.gray200};
  width: ${({ width }) => width || null};
  margin: ${({ m }) => m || null};
  height: ${({ height }) => height || "45px"};
  border: 1px solid ${({ border }) => border || theme.colors.gray200};
  border-radius: ${({ radius }) => radius || "25px"};
  background: ${({ background }) => background || theme.colors.white};
  opacity: 1;
  padding: 0px 10px;
  align-content: center;

  &&& {
    .ant-select {
      &-selection-search-input {
        border-radius: ${(props) => props.radius || "25px"};
        padding-left: 20px;
        font-size: ${theme.fonts.size.default};
        color: ${(props) => props.color || theme.colors.gray200};
      }

      &-selector,
      &-selection-search-input,
      &-selection-placeholder {
        padding-left: 5px;
        font-family: ${theme.fonts.family};
        font-size: ${theme.fonts.size.default};
        color: ${theme.colors.gray200} !important;
        border-color: ${(props) => props.border || "transparent"};
        height: ${(props) => props.height || "45px"};
        background-color: transparent;
        &:hover {
          border-color: ${(props) => props.border || "transparent"};
          border-radius: ${(props) => props.radius || "23px"};
        }
      }
      &-selection-placeholder,
      &-selection-item {
        font-family: ${theme.fonts.family};
        font-size: ${theme.fonts.size.default};
        color: ${(props) => props.color || theme.colors.gray950};
        line-height: ${(props) => props.height || "50px"};
      }
      &-selection-item {
        height: ${(props) => props.height || "50px"};
        padding-top: 1px;
        color: ${(props) => props.color || theme.colors.gray950};
        font-weight: ${(props) => props.weight || theme.fonts.weight.regular};
        text-transform: capitalize;
      }

      &-selector {
        align-items: center;
        text-align: initial;
        box-shadow: none !important;
        border-color: ${(props) => props.border || theme.colors.gray400};
        border-radius: ${(props) => props.radius || "25px"};

        Adding style when search .ant-select-selection-search {
          left: 0;
          right: 0;
          border-radius: ${(props) => props.radius || "25px"};
          color: ${(props) => props.color || theme.colors.gray950};
        }
        &:not(.ant-select-focused) {
          border: none;
        }
      }

      &.ant-select {
        &-open {
          &:not(.ant-select-show-search) {
            .ant-select-arrow {
              transform: rotate(-180deg);
              margin-right: ${(props) => props.allowClear && "8px"};
            }
          }
        }

        &-disabled {
          .ant-select-selection-placeholder {
            color: ${theme.colors.gray100};
          }
        }
        On Search &-show-search {
          .ant-select-selection-item {
            display: block;
            align-items: center;
          }
        }
      }
    }
  }
`;
