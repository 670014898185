import React from "react";
import PropTypes from "prop-types";
import { Modal } from "../../../components/Modal";
import { Text } from "../../../styles/Common";
import { theme } from "../../../styles/theme";
import { Button } from "../../../components/Button";
import { AlertIcon } from "../../../assets/icons";
import { useQueryClient } from "react-query";

const ModalWarningEdit = ({
  showModal,
  handleCloseModal,
  confirmEditOtp,
  setConfirmEditOtp,
  input,
  isMobile,
}) => {
  const queryClient = useQueryClient();

  const handleCloseType = () => {
    const newEdit = { ...confirmEditOtp };
    newEdit[input] = true;
    setConfirmEditOtp(newEdit);
    handleCloseModal();
  };

  return (
    <Modal
      open={showModal}
      onCancel={handleCloseType}
      padding={"29px 40px 32px 39px"}
      width={!isMobile ? "499px" : "100%"}
      height={"auto"}
    >
      <Modal.Header
        title={"Edición de información"}
        sizeTitle={theme.fonts.size.h1}
        colorTitle={theme.colors.blue500}
        weightTitle={theme.fonts.weight.bold}
        margin={"0 0 25px 0"}
        icon={<AlertIcon stroke={theme.colors.red} />}
        iconBackground={theme.colors.white50}
        iconBorderColor={theme.colors.red}
      />
      <Modal.Body>
        <Text
          size={theme.fonts.size.h6}
          color={theme.colors.gray200}
          weight={theme.fonts.weight.semibold}
          mb="32px"
        >
          Si editas la información de tu celular y/o correo electrónico es
          necesario realizar nuevamente el proceso de validación y verificación
          por medio de un código de validación. <br /> <br />
          ¿Deseas continuar?
        </Text>

        <Button width={"259px"} onClick={handleCloseType}>
          Continuar con la edición
        </Button>
      </Modal.Body>
    </Modal>
  );
};
ModalWarningEdit.propTypes = {
  showModal: PropTypes.bool,
  handleCloseModal: PropTypes.func,
  setConfirmEditOtp: PropTypes.func,
  isMobile: PropTypes.bool,
};
export default ModalWarningEdit;
