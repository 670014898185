import styled from "styled-components";
import { Input } from "antd";

export const StyledInputGroup = styled(Input.Group)`
  display: grid;
  grid-template-columns: ${(props) => props.proportion};

  &&& {
    // Input
    .ant-input,
    .ant-input-affix-wrapper,
    .ant-input-suffix-wrapper {
      min-width: initial;
      border-left: 0 !important;
    }

    // Select
    .ant-select {
      z-index: 1;
      width: 100%;
      min-width: initial;

      .ri-search-line {
        font-size: 18px !important;
      }

      &-selector {
        padding-right: 4px;
      }

      &-selection-search {
        padding-right: 5px !important;
      }

      &-selection-item {
        display: inline !important;
        padding-right: 0;

        img {
          margin-top: -3px;
        }
      }

      &-arrow {
        right: 1px !important;
      }
    }

    // Style Input depending on order
    & > div {
      &:first-child {
        .ant-input-affix-wrapper,
        .ant-input-suffix-wrapper,
        .ant-select-selector {
          border-right: 0;
        }
      }

      &:last-child {
        .ant-input,
        .ant-input-affix-wrapper,
        .ant-input-suffix-wrapper,
        .ant-select-selector {
          border-left: 0;
          padding-left: 30px;
          margin-left: -25px;
          border-radius: 0 100px 100px 0;
          &:hover {
            border-left: 0 !important;
          }
          &:focused {
            border-left: 0 !important;
          }
        }

        button {
          border-top-left-radius: 0;
          border-bottom-left-radius: 0;
        }
      }
    }
  }
`;
