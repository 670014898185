import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useForm, Controller } from "react-hook-form";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import toast from "react-hot-toast";
import { Modal } from "../../../components/Modal";
import { Text, Row, Div } from "../../../styles/Common";
import { theme } from "../../../styles/theme";
import { Button } from "../../../components/Button";
import { TimerIcon, VerifyIcon } from "../../../assets/icons";
import { Input } from "../../../components/Input";
import { decrypt } from "../../../utilities/helpers";
import useSendEmailOtp from "../../Login/hooks/useSendEmailOtp";
import useSendSmsOtp from "../../Login/hooks/useSendSmsOtp";
import { setVerificationTokens } from "../../../core/store/verificationCodes";
import useValidateAccount from "../../UserRegister/hooks/useValidateAccount";

const ModalOTPValidateAccount = ({
  showModalOTP,
  handleCloseModal,
  name,
  phone,
  email,
  setShowModal,
  isMobile,
  handleCloseSesion,
}) => {
  const {
    handleSubmit,
    control,
    setValue,
    setFocus,
    watch,
    formState: {},
  } = useForm();

  const dispatch = useDispatch();
  const validateAccount = useValidateAccount();

  const tokenRequested = useSelector(
    (state) => state.verificationCodes?.verificationTokens
  );

  const sendEmailOtp = useSendEmailOtp();
  const sendSmsOtp = useSendSmsOtp();

  const [showResendButton, setShowResendButton] = useState(false);
  const [showResendButtonPhone, setShowResendButtonPhone] = useState(false);
  const [timeLeft, setTimeLeft] = useState(180);
  const [isCodeValid, setIsCodeValid] = useState(false);
  const [isCodeValidPhone, setIsCodeValidPhone] = useState(false);
  const [errorCode, setErrorCode] = useState(false);
  const [errorCodePhone, setErrorCodePhone] = useState(false);
  const [expirationTime, setExpirationTime] = useState(1800);
  const [expirationTimePhone, setExpirationTimePhone] = useState(1800);

  useEffect(() => {
    if (isCodeValid && isCodeValidPhone) {
      return;
    }
    if (timeLeft <= 0) {
      setShowResendButton(true);
      setShowResendButtonPhone(true);
      return;
    }
    const timer = setTimeout(() => setTimeLeft(timeLeft - 1), 1000);
    return () => clearTimeout(timer);
  }, [timeLeft, isCodeValid, isCodeValidPhone]);

  useEffect(() => {
    if (showModalOTP) {
      setTimeLeft(180);
      setShowResendButton(false);
      setShowResendButtonPhone(false);
      setExpirationTime(1800);
      setExpirationTimePhone(1800);
    }
  }, [showModalOTP]);

  useEffect(() => {
    if (isCodeValid) {
      return;
    }
    if (expirationTime <= 0) {
      setShowResendButton(true);
      return;
    }
    const timer = setTimeout(() => setExpirationTime(expirationTime - 1), 1000);
    return () => clearTimeout(timer);
  }, [expirationTime, isCodeValid]);

  useEffect(() => {
    if (isCodeValidPhone) {
      return;
    }
    if (expirationTimePhone <= 0) {
      setShowResendButtonPhone(true);
      return;
    }
    const timer = setTimeout(
      () => setExpirationTimePhone(expirationTimePhone - 1),
      1000
    );
    return () => clearTimeout(timer);
  }, [expirationTimePhone, isCodeValidPhone]);

  const watchedFieldsEmail = watch([
    "code1",
    "code2",
    "code3",
    "code4",
    "code5",
  ]);
  useEffect(() => {
    const enteredCode = watchedFieldsEmail.join("");

    const decryptedToken = tokenRequested?.tokenEmail
      ? decrypt(tokenRequested?.tokenEmail)
      : null;
    const isCompleted = watchedFieldsEmail.every(
      (item) => item !== "" && item !== undefined && item !== null
    );

    if (enteredCode !== "" && enteredCode === decryptedToken) {
      setIsCodeValid(true);
    } else if (isCompleted && enteredCode !== decryptedToken) {
      setErrorCode(true);
    } else {
      setIsCodeValid(false);
      setErrorCode(false);
    }
  }, [watchedFieldsEmail, tokenRequested]);

  const watchedFieldsPhone = watch([
    "code1phone",
    "code2phone",
    "code3phone",
    "code4phone",
    "code5phone",
  ]);
  useEffect(() => {
    const enteredCode = watchedFieldsPhone.join("");

    const decryptedToken = tokenRequested?.tokenPhone
      ? decrypt(tokenRequested?.tokenPhone)
      : null;
    const isCompleted = watchedFieldsPhone.every(
      (item) => item !== "" && item !== undefined && item !== null
    );

    if (enteredCode !== "" && enteredCode === decryptedToken) {
      setIsCodeValidPhone(true);
    } else if (isCompleted && enteredCode !== decryptedToken) {
      setErrorCodePhone(true);
    } else {
      setIsCodeValidPhone(false);
      setErrorCodePhone(false);
    }
  }, [watchedFieldsPhone, tokenRequested]);

  const handleRequestOtp = (recoveryMethod) => {
    const action = recoveryMethod === "sendEmail" ? sendEmailOtp : sendSmsOtp;
    const dataToSend =
      recoveryMethod === "sendEmail"
        ? { email: email, userName: name, isPasswordReset: false }
        : { phoneNumber: phone, isPasswordReset: false };
    action.reset();
    action.mutate(dataToSend, {
      onSuccess: (res) => {
        if (recoveryMethod === "sendEmail") {
          setTimeLeft(120);
          setShowResendButton(false);
          setExpirationTime(1800);
          const codes = ["code1", "code2", "code3", "code4", "code5"];
          codes.forEach((code) => {
            setValue(code, "");
          });
          dispatch(
            setVerificationTokens({
              tokenEmail: res?.data?.result,
              tokenPhone: tokenRequested?.tokenPhone,
            })
          );
        } else {
          setTimeLeft(120);
          setShowResendButtonPhone(false);
          setExpirationTimePhone(1800);
          const codes = [
            "code1phone",
            "code2phone",
            "code3phone",
            "code4phone",
            "code5phone",
          ];
          codes.forEach((code) => {
            setValue(code, "");
          });
          dispatch(
            setVerificationTokens({
              tokenEmail: tokenRequested?.tokenEmail,
              tokenPhone: res?.data?.result,
            })
          );
        }

        toast.success("Se ha reenviado el código");
      },
      onError: (err) => {
        toast.error(err?.response?.data?.title || err?.response?.data);
      },
    });
  };

  const handleChangeOtp = (e, field, nextStep) => {
    const value = e.target.value;
    if (/^\d*$/.test(value)) {
      field.onChange(value);
    }
    if (value.length === 1) {
      setFocus(`code${nextStep}`, { shouldSelect: true });
      setTimeout(() => {
        const input = document.querySelector(`input[name="code${nextStep}"]`);
        if (input) {
          input.focus();
        }
      }, 0);
    }
  };
  const handleDelete = (e, field, nextStep) => {
    const value = e.target.value;
    if (/^\d*$/.test(value)) {
      field.onChange(value);
    }
    if (value === "") {
      setFocus(`code${nextStep - 2}`, { shouldSelect: true });
      setTimeout(() => {
        const input = document.querySelector(
          `input[name="code${nextStep - 2}"]`
        );
        if (input) {
          input.focus();
        }
      }, 0);
    }
  };

  const handleChangeOtpPhone = (e, field, nextStep) => {
    const value = e.target.value;
    if (/^\d*$/.test(value)) {
      field.onChange(value);
    }
    if (value.length === 1) {
      setFocus(`code${nextStep}`, { shouldSelect: true });
      setTimeout(() => {
        const input = document.querySelector(
          `input[name="code${nextStep}phone"]`
        );
        if (input) {
          input.focus();
        }
      }, 0);
    }
  };

  const handleDeletePhone = (e, field, nextStep) => {
    const value = e.target.value;
    if (/^\d*$/.test(value)) {
      field.onChange(value);
    }
    if (value === "") {
      setFocus(`code${nextStep - 2}`, { shouldSelect: true });
      setTimeout(() => {
        const input = document.querySelector(
          `input[name="code${nextStep - 2}phone"]`
        );
        if (input) {
          input.focus();
        }
      }, 0);
    }
  };

  const handleCloseAndReset = () => {
    const codes = [
      "code1",
      "code2",
      "code3",
      "code4",
      "code5",
      "code1phone",
      "code2phone",
      "code3phone",
      "code4phone",
      "code5phone",
    ];
    codes.forEach((code) => {
      setValue(code, "");
    });
    setShowResendButton(false);
    setShowResendButtonPhone(false);
    handleCloseModal();
  };

  const handleOtp = () => {
    validateAccount.reset();
    validateAccount.mutate(email, {
      onSuccess: () => {
        handleCloseAndReset();
        setShowModal(true);
      },
      onError: (err) => {
        toast.error(err?.response?.data);
      },
    });
  };

  return (
    <Modal
      open={showModalOTP}
      onCancel={() => {
        handleCloseSesion();
        handleCloseAndReset();
      }}
      height="auto"
      width={!isMobile ? "611px" : "100%"}
    >
      <Modal.Header
        title={"¡Verifica tu cuenta!"}
        sizeTitle={theme.fonts.size.h1}
        colorTitle={theme.colors.blue500}
        weightTitle={theme.fonts.weight.bold}
        margin={"0 0 24px 0"}
        align={"left"}
      />
      <Modal.Body>
        <Text
          size={theme.fonts.size.h6}
          color={theme.colors.gray200}
          mb="20px"
          align="left"
        >
          Hemos enviado un código de verificación al correo electrónico {""}
          <span style={{ color: theme.colors.red }}>
            {showModalOTP &&
              `${email.split("@")[0].slice(0, 2)}${"*".repeat(
                email.split("@")[0].length - 2
              )}@${email.split("@")[1]}`}
          </span>{" "}
          y al número{" "}
          <span style={{ color: theme.colors.red }}>
            {showModalOTP &&
              `${"*".repeat(phone.length - 2)}${phone.slice(-2)}`}
          </span>{" "}
        </Text>
        <Row justify="center" m="0 0 16px 0">
          <Div
            background={theme.colors.yellowlight}
            radius="50px"
            width="120px"
            height="60px"
            align="center"
            justify="center"
          >
            <TimerIcon
              width={"34px"}
              height={"34px"}
              stroke={theme.colors.blue500}
            />
            <Text
              color={theme.colors.blue500}
              weight={theme.fonts.weight.semibold}
              size={theme.fonts.size.h6}
            >
              {Math.floor(timeLeft / 60)}:
              {String(timeLeft % 60).padStart(2, "0")}
            </Text>
          </Div>
        </Row>
        <form onSubmit={handleSubmit(handleOtp)}>
          <Row>
            <Text
              mb="16px"
              size={theme.fonts.size.h6}
              weight={theme.fonts.weight.bold}
              color={theme.colors.blue500}
            >
              Correo electrónico
            </Text>
          </Row>
          <Div
            gap={!isMobile ? "48px" : "16px"}
            m={"0 0 8px 0"}
            align="center"
            justify="left"
            direction={isMobile && "column"}
          >
            <Row gap="16px" align="center" justify="left">
              <Controller
                name="code1"
                control={control}
                render={({ field }) => (
                  <Input
                    {...field}
                    width={"45px"}
                    height={"45px"}
                    border={theme.colors.gray200}
                    radius="16px"
                    background={theme.colors.white}
                    color={theme.colors.gray300}
                    maxLength={1}
                    onlyNumber
                    disabled={isCodeValid}
                    onChange={(event) => {
                      handleChangeOtp(event, field, 2);
                    }}
                  />
                )}
              />
              <Controller
                name="code2"
                control={control}
                render={({ field }) => (
                  <Input
                    {...field}
                    width={"45px"}
                    height={"45px"}
                    border={theme.colors.gray200}
                    radius="16px"
                    background={theme.colors.white}
                    color={theme.colors.gray300}
                    maxLength={1}
                    disabled={isCodeValid}
                    onChange={(event) => {
                      handleChangeOtp(event, field, 3);
                    }}
                    onKeyDown={(event) => {
                      if (event.key === "Backspace") {
                        handleDelete(event, field, 3);
                      }
                    }}
                  />
                )}
              />
              <Controller
                name="code3"
                control={control}
                render={({ field }) => (
                  <Input
                    {...field}
                    width={"45px"}
                    height={"45px"}
                    border={theme.colors.gray200}
                    radius="16px"
                    background={theme.colors.white}
                    color={theme.colors.gray300}
                    maxLength={1}
                    disabled={isCodeValid}
                    onChange={(event) => {
                      handleChangeOtp(event, field, 4);
                    }}
                    onKeyDown={(event) => {
                      if (event.key === "Backspace") {
                        handleDelete(event, field, 4);
                      }
                    }}
                  />
                )}
              />
              <Controller
                name="code4"
                control={control}
                render={({ field }) => (
                  <Input
                    {...field}
                    width={"45px"}
                    height={"45px"}
                    border={theme.colors.gray200}
                    radius="16px"
                    background={theme.colors.white}
                    color={theme.colors.gray300}
                    maxLength={1}
                    disabled={isCodeValid}
                    onChange={(event) => {
                      handleChangeOtp(event, field, 5);
                    }}
                    onKeyDown={(event) => {
                      if (event.key === "Backspace") {
                        handleDelete(event, field, 5);
                      }
                    }}
                  />
                )}
              />
              <Controller
                name="code5"
                control={control}
                render={({ field }) => (
                  <Input
                    {...field}
                    width={"45px"}
                    height={"45px"}
                    border={theme.colors.gray200}
                    radius="16px"
                    background={theme.colors.white}
                    color={theme.colors.gray300}
                    maxLength={1}
                    disabled={isCodeValid}
                    onChange={(event) => {
                      handleChangeOtp(event, field, 6);
                    }}
                    onKeyDown={(event) => {
                      if (event.key === "Backspace") {
                        handleDelete(event, field, 6);
                      }
                    }}
                  />
                )}
              />
            </Row>
            {isCodeValid && (
              <Div
                background={
                  expirationTime === 0
                    ? theme.colors.white50
                    : theme.colors.green100
                }
                radius="20px"
                width="141px"
                height="40px"
                align="center"
                justify="center"
                border={
                  expirationTime === 0
                    ? `1px solid ${theme.colors.red}`
                    : `1px solid ${theme.colors.green300}`
                }
                gap="10px"
              >
                {expirationTime !== 0 && (
                  <VerifyIcon stroke={theme.colors.green300} />
                )}
                {expirationTime === 0 ? (
                  <Text color={theme.colors.red}>Vencido</Text>
                ) : (
                  <Text color={theme.colors.green300}>Verificado</Text>
                )}
              </Div>
            )}
          </Div>
          {errorCode && (
            <Row m={showResendButton ? "0" : "0 0 12px 0"} justify="center">
              <Text size={theme.fonts.size.sm} color={theme.colors.red}>
                Código inválido
              </Text>
            </Row>
          )}
          {(expirationTime === 0 || (showResendButton && !isCodeValid)) && (
            <Row justify="center">
              <Text
                size={theme.fonts.size.sm}
                color={theme.colors.red}
                style={{ cursor: "pointer" }}
                onClick={() => handleRequestOtp("sendEmail")}
              >
                Reenviar código
              </Text>
            </Row>
          )}

          <Row>
            <Text
              mb="16px"
              mt="78px"
              size={theme.fonts.size.h6}
              weight={theme.fonts.weight.bold}
              color={theme.colors.blue500}
            >
              Celular
            </Text>
          </Row>
          <Div
            gap={!isMobile ? "48px" : "16px"}
            m={"0 0 8px 0"}
            align="center"
            justify="left"
            direction={isMobile && "column"}
          >
            <Row gap="16px" align="center" justify="left">
              <Controller
                name="code1phone"
                control={control}
                render={({ field }) => (
                  <Input
                    {...field}
                    width={"45px"}
                    height={"45px"}
                    border={theme.colors.gray200}
                    radius="16px"
                    background={theme.colors.white}
                    color={theme.colors.gray300}
                    maxLength={1}
                    onlyNumber
                    disabled={isCodeValidPhone}
                    onChange={(event) => {
                      handleChangeOtpPhone(event, field, 2);
                    }}
                  />
                )}
              />
              <Controller
                name="code2phone"
                control={control}
                render={({ field }) => (
                  <Input
                    {...field}
                    width={"45px"}
                    height={"45px"}
                    border={theme.colors.gray200}
                    radius="16px"
                    background={theme.colors.white}
                    color={theme.colors.gray300}
                    maxLength={1}
                    disabled={isCodeValidPhone}
                    onChange={(event) => {
                      handleChangeOtpPhone(event, field, 3);
                    }}
                    onKeyDown={(event) => {
                      if (event.key === "Backspace") {
                        handleDeletePhone(event, field, 3);
                      }
                    }}
                  />
                )}
              />
              <Controller
                name="code3phone"
                control={control}
                render={({ field }) => (
                  <Input
                    {...field}
                    width={"45px"}
                    height={"45px"}
                    border={theme.colors.gray200}
                    radius="16px"
                    background={theme.colors.white}
                    color={theme.colors.gray300}
                    maxLength={1}
                    disabled={isCodeValidPhone}
                    onChange={(event) => {
                      handleChangeOtpPhone(event, field, 4);
                    }}
                    onKeyDown={(event) => {
                      if (event.key === "Backspace") {
                        handleDeletePhone(event, field, 4);
                      }
                    }}
                  />
                )}
              />
              <Controller
                name="code4phone"
                control={control}
                render={({ field }) => (
                  <Input
                    {...field}
                    width={"45px"}
                    height={"45px"}
                    border={theme.colors.gray200}
                    radius="16px"
                    background={theme.colors.white}
                    color={theme.colors.gray300}
                    maxLength={1}
                    disabled={isCodeValidPhone}
                    onChange={(event) => {
                      handleChangeOtpPhone(event, field, 5);
                    }}
                    onKeyDown={(event) => {
                      if (event.key === "Backspace") {
                        handleDeletePhone(event, field, 5);
                      }
                    }}
                  />
                )}
              />
              <Controller
                name="code5phone"
                control={control}
                render={({ field }) => (
                  <Input
                    {...field}
                    width={"45px"}
                    height={"45px"}
                    border={theme.colors.gray200}
                    radius="16px"
                    background={theme.colors.white}
                    color={theme.colors.gray300}
                    maxLength={1}
                    disabled={isCodeValidPhone}
                    onChange={(event) => {
                      handleChangeOtpPhone(event, field, 6);
                    }}
                    onKeyDown={(event) => {
                      if (event.key === "Backspace") {
                        handleDeletePhone(event, field, 6);
                      }
                    }}
                  />
                )}
              />
            </Row>
            {isCodeValidPhone && (
              <Div
                background={
                  expirationTimePhone === 0
                    ? theme.colors.white50
                    : theme.colors.green100
                }
                radius="20px"
                width="141px"
                height="40px"
                align="center"
                justify="center"
                border={
                  expirationTimePhone === 0
                    ? `1px solid ${theme.colors.red}`
                    : `1px solid ${theme.colors.green300}`
                }
                gap="10px"
              >
                {expirationTimePhone !== 0 && (
                  <VerifyIcon stroke={theme.colors.green300} />
                )}
                {expirationTimePhone === 0 ? (
                  <Text color={theme.colors.red}>Vencido</Text>
                ) : (
                  <Text color={theme.colors.green300}>Verificado</Text>
                )}
              </Div>
            )}
          </Div>
          {errorCodePhone && (
            <Row
              m={showResendButtonPhone ? "0" : "0 0 12px 0"}
              justify="center"
            >
              <Text size={theme.fonts.size.sm} color={theme.colors.red}>
                Código inválido
              </Text>
            </Row>
          )}
          {(expirationTimePhone === 0 ||
            (showResendButtonPhone && !isCodeValidPhone)) && (
            <Row m="0 0 24px 0" justify="center">
              <Text
                size={theme.fonts.size.sm}
                color={theme.colors.red}
                style={{ cursor: "pointer" }}
                onClick={() => handleRequestOtp("sendSms")}
              >
                Reenviar código
              </Text>
            </Row>
          )}

          <Row m="30px 0 0 0" justify="center">
            <Button
              width={"165px"}
              htmlType="submit"
              disabled={
                !isCodeValid ||
                !isCodeValidPhone ||
                expirationTime === 0 ||
                expirationTimePhone === 0
              }
              loading={validateAccount.isLoading}
            >
              Continuar
            </Button>
          </Row>
        </form>
      </Modal.Body>
    </Modal>
  );
};

ModalOTPValidateAccount.propTypes = {
  showModalOTP: PropTypes.bool,
  handleCloseModal: PropTypes.func,
  name: PropTypes.string,
  phone: PropTypes.string,
  email: PropTypes.string,
  setShowModal: PropTypes.func,
  isMobile: PropTypes.bool,
  handleCloseSesion: PropTypes.func,
};

export default ModalOTPValidateAccount;
