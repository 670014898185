import { useQuery } from "react-query";
import { axiosClient } from "../services/axiosInstance";

const useGetCityList = (departmentId) => {
  const data = useQuery(
    ["getCityList", departmentId],
    () => axiosClient.get(`City/GetGetCityList?deparmentId=${departmentId}`),
    { enabled: !!departmentId }
  );

  return data;
};
export default useGetCityList;
