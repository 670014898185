import React from "react";
import PropTypes from "prop-types";
import { Modal } from "../../../components/Modal";
import { Text } from "../../../styles/Common";
import { theme } from "../../../styles/theme";
import { Button } from "../../../components/Button";
import { LikeIcon } from "../../../assets/icons";
import { useNavigate } from "react-router-dom";
import { useQueryClient } from "react-query";

const ModalSuccess = ({ showModal, handleCloseModal, isMobile }) => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const handleContinue = () => {
    queryClient.invalidateQueries(["getValidateIsFilledProfile"]);
    navigate("/platform");
  };

  return (
    <Modal
      open={showModal}
      onCancel={handleCloseModal}
      padding={!isMobile ? "29px 63px 37px 63px" : "29px 25px 37px 25px"}
      width={!isMobile ? "499px" : "100%"}
      height={!isMobile ? "454px" : "auto"}
    >
      <Modal.Header
        title={"¡Gracias por tus respuestas!"}
        sizeTitle={theme.fonts.size.h1}
        colorTitle={theme.colors.blue500}
        weightTitle={theme.fonts.weight.bold}
        margin={"0 0 27px 0"}
        icon={<LikeIcon stroke={theme.colors.green300} />}
        iconBackground={theme.colors.white300}
        style={{ lineHeight: "50px" }}
      />
      <Modal.Body>
        <Text
          size={theme.fonts.size.h6}
          color={theme.colors.gray200}
          weight={theme.fonts.weight.semibold}
          mb="32px"
        >
          Ahora puedes ingresar a la plataforma y disfrutar de la experiencia
          que trae la {""}
          <span style={{ color: theme.colors.red }}>Pulpeybox</span> para ti
        </Text>

        <Button width={"157px"} onClick={handleContinue}>
          Ingresar
        </Button>
      </Modal.Body>
    </Modal>
  );
};
ModalSuccess.propTypes = {
  showModal: PropTypes.bool,
  handleCloseModal: PropTypes.func,
  isMobile: PropTypes.bool,
};
export default ModalSuccess;
