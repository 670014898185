import { useQuery } from "react-query";
import { axiosClient } from "../services/axiosInstance";

const useGetGender = () => {
  const data = useQuery(["getGender"], () =>
    axiosClient.post(`Setting/GepSettingList?groupSettingId=3`)
  );
  return data;
};
export default useGetGender;
