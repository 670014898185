import React, { useState, useEffect } from "react";
import { PageWrapper } from "./styles";
import dayjs from "dayjs";
import { Div, Text, Row, Col } from "../../styles/Common";
import { theme } from "../../styles/theme";
import logo from "../../assets/images/logo.svg";
import { CopywriteFooter } from "../../components/CopywriteFooter";
import { Card } from "../../components/Card";
import useGetUserProfile from "./hooks/useGetUserProfile";
import { getUserInfo } from "../../utilities/helpers";
import ModalOTPInfoProfile from "./components/ModalOTPInfoProfile";
import { Step1 } from "./components/Step1";
import { useQueryClient } from "react-query";
import useGetPhoneCode from "../../core/hooks/useGetPhoneCode";
import { Step2 } from "./components/Step2";
import { Step3 } from "./components/Step3";
import { useOutletContext } from "react-router-dom";

export const ProfileInfo = () => {
  const { isMobile } = useOutletContext();
  const queryClient = useQueryClient();
  const [showModalOTP, setShowModalOTP] = useState(false);
  const [isVerifiedEmail, setIsVerifiedEmail] = useState(true);
  const [isVerifiedPhone, setIsVerifiedPhone] = useState(true);
  const [formStep1, setFormStep1] = useState({});
  const [formStep2, setFormStep2] = useState({});
  const [formStep3, setFormStep3] = useState({});
  const [dataInitialStep1, setDataInitialStep1] = useState({
    email: "",
    phone: "",
  });

  const [stepNumber, setStepNumber] = useState(1);

  const getPhoneCode = useGetPhoneCode();
  const { data, isSuccess } = getPhoneCode;
  const [optionsCodes, setOptionsCodes] = useState([]);
  useEffect(() => {
    if (isSuccess) {
      setOptionsCodes(
        data?.data?.result.map((item) => ({
          label: item.code,
          value: item.id,
        }))
      );
    }
  }, [data, isSuccess]);

  const { nameid } = getUserInfo();
  const getUserProfile = useGetUserProfile(Number(nameid));
  const { data: dataUserProfile } = getUserProfile;
  useEffect(() => {
    setFormStep1({
      ind: dataUserProfile?.data?.result[0].phoneNumberCode || null,
      phone: dataUserProfile?.data?.result[0].phoneNumber || null,
      email: dataUserProfile?.data?.result[0].email || "",
      department: dataUserProfile?.data?.result[0].departmentId || null,
      municipality: dataUserProfile?.data?.result[0].cityId || null,
      address: dataUserProfile?.data?.result[0].address || "",
      userId: dataUserProfile?.data?.result[0].userId || nameid,
      instruction: dataUserProfile?.data?.result[0].instruction || "",
      exactAddress: dataUserProfile?.data?.result[0].exactAddress || "",
      zone: dataUserProfile?.data?.result[0].zone || "",
      latitude: Number(dataUserProfile?.data?.result[0].latitude) || null,
      longitude: Number(dataUserProfile?.data?.result[0].longitude) || null,
    });
    setDataInitialStep1({
      email: dataUserProfile?.data?.result[0].email || "",
      phone: dataUserProfile?.data?.result[0].phoneNumber || null,
    });
    setFormStep2({
      userId: dataUserProfile?.data?.result[0].userId || nameid,
      name: dataUserProfile?.data?.result[0].name || "",
      lastName: dataUserProfile?.data?.result[0].lastName || "",
      maritalStatus: dataUserProfile?.data?.result[0].maritalStatusId || null,
      gender: dataUserProfile?.data?.result[0].genderId || null,
      birthdate: !!dataUserProfile?.data?.result[0].birthDate
        ? dayjs(dataUserProfile?.data?.result[0].birthDate)
        : null,
      role: dataUserProfile?.data?.result[0].roleHouseId || null,
    });
    setFormStep3({
      userId: dataUserProfile?.data?.result[0].userId || nameid,
      incomeRange: dataUserProfile?.data?.result[0].incomeRangeId || null,
      numberChildren: dataUserProfile?.data?.result[0].numberChildrenId || null,
      physicalActivities:
        dataUserProfile?.data?.result[0].frequencyActivitiesPhysicalId || null,
      pets: dataUserProfile?.data?.result[0].petsId || null,
      profession: dataUserProfile?.data?.result[0].professionsId || null,
      instagram: dataUserProfile?.data?.result[0].instagram || "",
      householdRole: dataUserProfile?.data?.result[0].isBuyManagerHome || false,
      isPregnant: dataUserProfile?.data?.result[0].isPregnant || false,
      isInterestedTechnology:
        dataUserProfile?.data?.result[0].isInterestedTechnology || false,
      hobbies: dataUserProfile?.data?.result[0].hobbiesId || null,
    });
  }, [dataUserProfile]);

  const handleCloseModal = () => {
    setShowModalOTP(false);
  };

  const returnStep = (step) => {
    queryClient.invalidateQueries(["getUserProfile"]);
    setStepNumber(step);
  };

  return (
    <PageWrapper isMobile={isMobile}>
      <ModalOTPInfoProfile
        showModalOTP={showModalOTP}
        handleCloseModal={handleCloseModal}
        formStep1={formStep1}
        isVerifiedEmail={isVerifiedEmail}
        isVerifiedPhone={isVerifiedPhone}
        setStepNumber={setStepNumber}
        optionsCodes={optionsCodes}
        isMobile={isMobile}
      />
      <Div
        width={"100%"}
        height={"99px"}
        justify="space-between"
        p={!isMobile ? "28px 98px" : "28px"}
        align="center"
        m="0 0 20px 0"
        style={{ backdropFilter: "blur(16px)" }}
      >
        <img src={logo} alt="logo" width={"154.65px"} height={"35.33px"} />
      </Div>
      <Div
        m="0 0 68px 0"
        justify={!isMobile ? "center" : "left"}
        width={!isMobile ? "100%" : "260px"}
      >
        <Text
          size={!isMobile ? theme.fonts.size.h1i : theme.fonts.size.h3}
          color={theme.colors.white}
          weight={theme.fonts.weight.semibold}
        >
          Completa tu perfil
        </Text>
      </Div>
      <Row
        gap={!isMobile ? "123px" : "0"}
        width="100%"
        justify="center"
        style={
          !isMobile
            ? { zIndex: 1, position: "absolute", top: "200px" }
            : { zIndex: 1, position: "absolute", top: "242px" }
        }
      >
        <Col align="center" justify="center">
          <Div
            width={!isMobile ? "55px" : "40px"}
            height={!isMobile ? "55px" : "40px"}
            radius="50px"
            background={theme.colors.red}
            align="center"
            justify="center"
            m="0 0 11px 0"
            onClick={stepNumber > 1 ? () => returnStep(1) : null}
            style={{ cursor: stepNumber > 1 ? "pointer" : null }}
          >
            <Text
              color={theme.colors.white}
              size={!isMobile ? theme.fonts.size.h4 : theme.fonts.size.h5}
              weight={theme.fonts.weight.bold}
            >
              1
            </Text>
          </Div>
          <Text
            color={stepNumber === 1 ? theme.colors.white : theme.colors.gray200}
            weight={
              stepNumber === 1
                ? theme.fonts.weight.bold
                : theme.fonts.weight.regular
            }
            size={!isMobile ? theme.fonts.size.default : theme.fonts.size.sm}
            style={{
              width: "108px",
              cursor: stepNumber > 1 ? "pointer" : null,
            }}
            onClick={stepNumber > 1 ? () => returnStep(1) : null}
          >
            Información de contacto
          </Text>
        </Col>
        <Col align="center" justify="center">
          <Div
            width={!isMobile ? "55px" : "40px"}
            height={!isMobile ? "55px" : "40px"}
            radius="50px"
            background={theme.colors.red}
            align="center"
            justify="center"
            m="0 0 11px 0"
            onClick={stepNumber > 2 ? () => setStepNumber(2) : null}
            style={{ cursor: stepNumber > 2 ? "pointer" : null }}
          >
            <Text
              color={theme.colors.white}
              size={!isMobile ? theme.fonts.size.h4 : theme.fonts.size.h5}
              weight={theme.fonts.weight.bold}
            >
              2
            </Text>
          </Div>
          <Text
            color={stepNumber === 2 ? theme.colors.white : theme.colors.gray200}
            weight={
              stepNumber === 2
                ? theme.fonts.weight.bold
                : theme.fonts.weight.regular
            }
            size={!isMobile ? theme.fonts.size.default : theme.fonts.size.sm}
            style={{
              width: "108px",
              cursor: stepNumber > 2 ? "pointer" : null,
            }}
            onClick={stepNumber > 2 ? () => returnStep(2) : null}
          >
            Información personal
          </Text>
        </Col>
        <Col align="center" justify="center">
          <Div
            width={!isMobile ? "55px" : "40px"}
            height={!isMobile ? "55px" : "40px"}
            radius="50px"
            background={theme.colors.red}
            align="center"
            justify="center"
            m="0 0 11px 0"
          >
            <Text
              color={theme.colors.white}
              size={!isMobile ? theme.fonts.size.h4 : theme.fonts.size.h5}
              weight={theme.fonts.weight.bold}
            >
              3
            </Text>
          </Div>
          <Text
            color={stepNumber === 3 ? theme.colors.white : theme.colors.gray200}
            weight={
              stepNumber === 3
                ? theme.fonts.weight.bold
                : theme.fonts.weight.regular
            }
            size={!isMobile ? theme.fonts.size.default : theme.fonts.size.sm}
            style={{ width: "108px" }}
          >
            Información adicional *
          </Text>
        </Col>
      </Row>
      <Card
        width={!isMobile ? "1132px" : "326px"}
        height={"auto"}
        p={!isMobile ? "158px 60px 45px 60px" : "90px 25px  35px 25px"}
        m=" 0 0 18px 0"
      >
        {stepNumber === 1 && (
          <Step1
            formStep1={formStep1}
            setFormStep1={setFormStep1}
            dataInitial={dataInitialStep1}
            isVerifiedEmail={isVerifiedEmail}
            setIsVerifiedEmail={setIsVerifiedEmail}
            isVerifiedPhone={isVerifiedPhone}
            setIsVerifiedPhone={setIsVerifiedPhone}
            setShowModalOTP={setShowModalOTP}
            setStepNumber={setStepNumber}
            optionsCodes={optionsCodes}
            isMobile={isMobile}
          />
        )}
        {stepNumber === 2 && (
          <Step2
            formStep2={formStep2}
            setStepNumber={setStepNumber}
            isMobile={isMobile}
          />
        )}
        {stepNumber === 3 && (
          <Step3
            formStep3={formStep3}
            setStepNumber={setStepNumber}
            isMobile={isMobile}
          />
        )}
      </Card>

      <CopywriteFooter />
    </PageWrapper>
  );
};
