import { configureStore } from "@reduxjs/toolkit";
import { combineReducers } from "redux";
import common from "./common";
import auth from "./auth";
import verificationCodes from "./verificationCodes";

const reducer = combineReducers({
  common,
  auth,
  verificationCodes,
});

export const store = configureStore({
  reducer,
});
