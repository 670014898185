import React from "react";
import { CustomDrawer } from "./styles";
import { theme } from "../../styles/theme";

export const Drawer = ({
  closelabel,
  closeIcon,
  children,
  background,
  ...rest
}) => {
  return (
    <CustomDrawer
      closelabel={closelabel}
      closeIcon={closeIcon}
      background={background}
      {...rest}
    >
      {children}
    </CustomDrawer>
  );
};
