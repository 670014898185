import { useQuery } from "react-query";
import { axiosClient } from "../../../core/services/axiosInstance";

const useGetProfileUserSummary = (userId) => {
  const data = useQuery(
    ["getProfileUserSummary", userId],
    () => axiosClient.post(`/User/GetProfileUserSummary?userId=${userId}`),
    { enabled: !!userId }
  );

  return data;
};
export default useGetProfileUserSummary;
