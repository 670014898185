export const theme = {
  colors: {
    white: "#FFFFFF",
    white50: "#FFF4F4",
    whiteBlur: "rgba(255, 255, 255, 0.1)",
    white100: "#FFFFFF12",
    white200: "#FEFFFF",
    white300: "#F7FFFD",
    black: "#000000",
    blackBlur1: `rgba(0, 0, 0, 0.24)`,
    blackBlur2: `rgba(22, 21, 37, 0.2)`,
    blue50: "#6a6888",
    blue100: "#323f57",
    blue500: "#22304A",
    blue700: "#413F5F",
    black500: "#272727",
    black600: "#11101C",
    yellow: "#FDED4F",
    yellow50: "#FFFBE5",
    yellowlight: "#FDEFA9",
    red50: "#FFCBCB",
    red: "#FE4F51",
    red500: "#D83739",
    pink: "#D8373957",
    pink100: "#F8616321",
    pink200: "#FE4F5157",
    purple: "#4C4A6F",
    gray50: "#00000029",
    gray100: "#EAEEF0",
    gray200: "#ACB8C3",
    gray300: "#5A6A7B",
    gray400: "#666A6E",
    green100: "#38C7A61C",
    green300: "#38C7A6",
    purple500: "#503E5E",

    scroll: {
      gray: "#D9D9D9",
      green: "#00565F",
    },
    alerts: {
      success: "#CADDD4",
      error: "#FFE5E5",
      iconSuccess: "#4A7668",
      iconError: "#F87C7C",
    },
  },

  fonts: {
    family: "Nunito, sans-serif",
    size: {
      xl: "5.5625rem", //89px
      h1: "2.5rem", //40px
      h1i: "2.375rem", //38px
      h2: "2.25rem", //36px
      h3: "2rem", //32px
      h4: "1.5rem", //24px
      h5: "1.375", //22px
      h6: "1.125rem", //18px
      default: "1rem", // 16px
      sm: "0.875rem", //14px
      xs: "0.75rem",
    },
    weight: {
      extralight: 200,
      light: 300,
      regular: 400,
      medium: 500,
      semibold: 600,
      bold: 700,
      extrabold: 800,
    },
  },
  breakpoints: {
    xs: "320px",
    sm: "480px",
    md: "820px",
    lg: "992px",
    xl: "1200px",
  },
  zIndex: {
    base: 1,
    menu: 2,
    overlay: 3,
    modal: 4,
    toast: 5,
  },
};
