import React from "react";
import PropTypes from "prop-types";

import { CustomProgress } from "./styles";

export const Progress = ({ percent, showInfo, radius, height, background }) => {
  return (
    <CustomProgress
      percent={percent}
      showInfo={showInfo}
      radius={radius}
      height={height}
      background={background}
    />
  );
};

Progress.propTypes = {
  percent: PropTypes.number,
  showInfo: PropTypes.bool,
  radius: PropTypes.string,
  height: PropTypes.string,
};
