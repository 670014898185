import styled from "styled-components";
import { Radio } from "antd";
import { theme } from "../../styles/theme";

export const AntRadio = styled(Radio)`
  margin: ${({ m }) => m || "0 0 0 0"};
  color: ${({ color }) => color || theme.colors.white};

  /* Override Ant Styles */
  && {
    &.ant-radio-wrapper {
      &-disabled {
        .ant-radio {
          & + span {
            color: ${theme.colors.gray200};
          }
        }
      }
    }

    .ant-radio {
      .ant-radio-inner {
        border-width: 2px;
        border-color: ${(props) =>
          props.error ? theme.colors.red : theme.colors.red};
      }

      &-checked {
        .ant-radio-inner {
          border-color: ${theme.colors.red};
          background-color: ${({ background }) =>
            background || theme.colors.white};

          &:after {
            background-color: ${theme.colors.red};
          }
        }
      }

      & + span {
        color: ${theme.colors.green};
        font-family: ${theme.fonts.family};
      }
    }

    .ant-radio:not(.ant-radio-checked) .ant-radio-inner {
      border-color: ${theme.colors.red};
      background-color: ${({ background }) => background || theme.colors.white};
    }
  }
`;
