import { useQuery } from "react-query";
import { axiosClient } from "../../../core/services/axiosInstance";

const useGetUserProfile = (userId) => {
  const data = useQuery(
    ["getUserProfile", userId],
    () => axiosClient.get(`/UserProfile/GetUserProfile?userId=${userId}`),
    { enabled: !!userId }
  );

  return data;
};
export default useGetUserProfile;
