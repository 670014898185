import { useQuery } from "react-query";
import { axiosClient } from "../services/axiosInstance";

const useGetPets = () => {
  const data = useQuery(["getPets"], () =>
    axiosClient.post(`Setting/GepSettingList?groupSettingId=21`)
  );
  return data;
};
export default useGetPets;
