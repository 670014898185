import { Progress } from "antd";
import styled from "styled-components";
import { theme } from "../../styles/theme";

export const CustomProgress = styled(Progress)`
  margin: 0px;
  text-align: center;
  && {
    &.ant-progress {
      & .ant-progress-inner {
        background-color: ${theme.colors.blue100};
        border-radius: ${({ radius }) => radius || "2px"} !important;
        height: ${({ height }) => height || "4px"};
        width: 90%;
      }

      & .ant-progress-bg {
        background-color: ${({ background }) =>
          background || theme.colors.green300};
        border-radius: ${({ radius }) => radius || "2px"} !important;
        height: ${({ height }) => height || "4px"} !important;
      }

      & .ant-progress-text {
        font-family: ${theme.fonts.family};
        font-size: ${theme.fonts.size.sm};
        color: ${theme.colors.green};
      }
    }
  }
`;
