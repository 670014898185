import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";
import { theme } from "../../../styles/theme";
import { Col, Row, Div, Text } from "../../../styles/Common";
import { Card } from "../../../components/Card";
import { CopywriteFooter } from "../../../components/CopywriteFooter";
import {
  PhoneIcon,
  EmailIcon,
  PinIcon,
  ChevronRightIcon,
  EditIcon,
} from "../../../assets/icons";
import Tabs from "../../../components/Tabs";
import useGetProfileUserSummary from "../hooks/useGetProfileUserSummary";
import { getUserInfo } from "../../../utilities/helpers";
import { getLabelById } from "../../../utilities/helpers";
import useGetPhoneCode from "../../../core/hooks/useGetPhoneCode";

export const MyAccount = ({ setEditMyAccount, isMobile }) => {
  const navigate = useNavigate();
  const [activeKey, setActiveKey] = useState("1");
  const [dataProfile, setDataProfile] = useState({});

  const { nameid } = getUserInfo();
  const getProfileUserSummary = useGetProfileUserSummary(nameid);
  const { data } = getProfileUserSummary;

  const getPhoneCode = useGetPhoneCode();
  const { data: dataPhonecode, isSuccess: isSuccessPhoneCode } = getPhoneCode;
  const [optionsCodes, setOptionsCodes] = useState([]);
  useEffect(() => {
    if (isSuccessPhoneCode) {
      setOptionsCodes(
        dataPhonecode?.data?.result.map((item) => ({
          label: item.code,
          value: item.id,
        }))
      );
    }
  }, [dataPhonecode, isSuccessPhoneCode]);

  useEffect(() => {
    setDataProfile({
      phoneNumber: data?.data?.result?.phoneNumber,
      ind: getLabelById(data?.data?.result?.phoneCodeId, optionsCodes),
      email: data?.data?.result?.email,
      address: data?.data?.result?.address,
      name: data?.data?.result?.name,
      lastName: data?.data?.result?.lastName,
    });
  }, [data]);

  const initials =
    dataProfile?.name && dataProfile?.lastName
      ? `${dataProfile?.name.charAt(0)}${dataProfile?.lastName.charAt(
          0
        )}`.toUpperCase()
      : "NN";

  const panels = [
    {
      tab: "Pendientes por contestar",
      key: "1",
    },
    {
      tab: "Encuestas respondidas",
      key: "2",
    },
  ];

  const handleTabChange = (key) => {
    setActiveKey(key);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Div
        direction={!isMobile ? "row" : "column"}
        gap="17px"
        m={!isMobile ? "49px 0 43px 0" : "25px 0 55px 0"}
        align={isMobile && "center"}
      >
        <Col>
          <Card width={!isMobile ? "379px" : "326px"} height="auto" p="24px">
            <Row width="100%" justify="right">
              <Div
                height="40px"
                width="40px"
                radius="50px"
                background={theme.colors.blue100}
                border={`1px solid ${theme.colors.white}`}
                onClick={() => setEditMyAccount(true)}
                align="center"
                justify="center"
                style={{ cursor: "pointer" }}
              >
                <EditIcon stroke={theme.colors.white} />
              </Div>
            </Row>

            <Col align="center" gap="24px">
              <Div
                height="80px"
                width="80px"
                radius="50px"
                background={theme.colors.red}
                align="center"
                justify="center"
              >
                <Text
                  color={theme.colors.white}
                  weight={theme.fonts.weight.bold}
                  size={theme.fonts.size.h3}
                >
                  {initials}
                </Text>
              </Div>
              <Text
                size={theme.fonts.size.h4}
                color={theme.colors.white}
                weight={theme.fonts.weight.bold}
                mb={!isMobile ? "40px" : "25px"}
                style={{ width: !isMobile ? "331px" : "100%" }}
              >
                {dataProfile?.name?.split(" ")[0]}{" "}
                {dataProfile?.lastName?.split(" ")[0]}
              </Text>
              <Div
                width="296px"
                height="51px"
                background={theme.colors.blue100}
                radius="26px"
                align="center"
                justify="left"
                gap="9px"
                p="4px"
              >
                <Div
                  width="42px"
                  height="42px"
                  radius="50px"
                  background={theme.colors.red}
                  align="center"
                  justify="center"
                >
                  <PhoneIcon stroke={theme.colors.white} />
                </Div>
                <Text color={theme.colors.white}>
                  {dataProfile.ind} {dataProfile.phoneNumber}
                </Text>
              </Div>
              <Div
                width="296px"
                height="51px"
                background={theme.colors.blue100}
                radius="26px"
                align="center"
                justify="left"
                gap="9px"
                p="4px"
              >
                <Div
                  width="42px"
                  height="42px"
                  radius="50px"
                  align="center"
                  justify="center"
                  background={theme.colors.red}
                >
                  <EmailIcon stroke={theme.colors.white} />
                </Div>
                <Text color={theme.colors.white} align="webkit-left">
                  {dataProfile?.email}
                </Text>
              </Div>
              <Div
                width="296px"
                height="51px"
                background={theme.colors.blue100}
                radius="26px"
                align="center"
                justify="left"
                gap="9px"
                p="4px"
                style={{ textAlign: "left" }}
              >
                <Div
                  width="42px"
                  height="42px"
                  radius="50px"
                  background={theme.colors.red}
                  align="center"
                  justify="center"
                  style={{ minWidth: "42px" }}
                >
                  <PinIcon stroke={theme.colors.white} />
                </Div>
                <Text color={theme.colors.white}> {dataProfile?.address}</Text>
              </Div>
              <Text
                weight={theme.fonts.weight.bold}
                color={theme.colors.white}
                style={{ cursor: "pointer" }}
                mt="6px"
                onClick={() => navigate("/ResetPasswordPlatform")}
              >
                Cambiar de contraseña
              </Text>
            </Col>
          </Card>
        </Col>
        <Col>
          <Card
            width={!isMobile ? "775px" : "326px"}
            height={!isMobile ? "536px" : "auto"}
            p="35px 35px 51px 38px"
          >
            <Row>
              <Text
                weight={theme.fonts.weight.bold}
                color={theme.colors.white}
                size={theme.fonts.size.h3}
                mb="24px"
              >
                Aumenta tus posibilidades
              </Text>
            </Row>
            <Row m="0 0 51px 0">
              <Text
                weight={theme.fonts.weight.semibold}
                color={theme.colors.white}
                size={theme.fonts.size.h6}
                style={{ lineHeight: "30px" }}
              >
                Completa las encuestas que tenemos a continuación y aumenta tus
                posibilidades de recibir la{" "}
                <span style={{ color: theme.colors.red }}>Pulpeybox.</span>{" "}
                <br />
                <br />
                Recuerda que estas preguntas ayudan a las marcas a enviarte los
                productos correctos de acuerdo a tu estilo de vida y a la etapa
                de vida en la que estás actualmente.
              </Text>
            </Row>
            <Div direction={!isMobile ? "row" : "column"} m="0 0 15px 0">
              <Tabs
                defaultActiveKey="1"
                onChange={handleTabChange}
                activeKey={activeKey}
                size={theme.fonts.size.default}
                gutter={!isMobile ? 42 : 10}
                tabPosition="left"
              >
                {panels.map((panel) => (
                  <Tabs.Panel tab={panel.tab} key={panel.key} />
                ))}
              </Tabs>
            </Div>
            <Div
              direction={!isMobile ? "row" : "column"}
              gap="21.3px"
              align={isMobile && "center"}
            >
              <Div
                width={!isMobile ? "219px" : "100%"}
                height="120px"
                radius="24px"
                background={theme.colors.red}
                align="left"
                gap="41px"
                p="20px 17px 20px 24px"
              >
                <Col>
                  <Text color={theme.colors.white} mb="9px">
                    Encuesta 1
                  </Text>
                  <Text
                    weight={theme.fonts.weight.bold}
                    color={theme.colors.white}
                    size={theme.fonts.size.h6}
                  >
                    Alimentos y bebidas
                  </Text>
                </Col>
                <Col justify="center">
                  <ChevronRightIcon
                    width="32px"
                    height="32px"
                    stroke={theme.colors.white}
                  />
                </Col>
              </Div>
              <Div
                width={!isMobile ? "219px" : "100%"}
                height="120px"
                radius="24px"
                background={theme.colors.green300}
                align="left"
                gap="41px"
                p="20px 17px 20px 24px"
              >
                <Col>
                  <Text color={theme.colors.white} mb="9px">
                    Encuesta 2
                  </Text>
                  <Text
                    weight={theme.fonts.weight.bold}
                    color={theme.colors.white}
                    size={theme.fonts.size.h6}
                  >
                    Cuidado personal
                  </Text>
                </Col>
                <Col justify="center">
                  <ChevronRightIcon
                    width="32px"
                    height="32px"
                    stroke={theme.colors.white}
                  />
                </Col>
              </Div>
              <Div
                width={!isMobile ? "219px" : "100%"}
                height="120px"
                radius="24px"
                background={theme.colors.yellowlight}
                align="left"
                gap="41px"
                p="20px 17px 20px 24px"
              >
                <Col>
                  <Text color={theme.colors.gray300} mb="9px">
                    Encuesta 3
                  </Text>
                  <Text
                    weight={theme.fonts.weight.bold}
                    color={theme.colors.gray300}
                    size={theme.fonts.size.h6}
                  >
                    Salud y Medicamentos
                  </Text>
                </Col>
                <Col justify="center">
                  <ChevronRightIcon
                    width="32px"
                    height="32px"
                    stroke={theme.colors.gray300}
                  />
                </Col>
              </Div>
            </Div>
          </Card>
        </Col>
      </Div>
      <CopywriteFooter />
    </>
  );
};

MyAccount.propTypes = {
  setEditMyAccount: PropTypes.func,
  isMobile: PropTypes.bool,
};
