import { useQuery } from "react-query";
import { axiosClient } from "../../../core/services/axiosInstance";

const useGetValidateIsFilledProfile = (nameId) => {
  const data = useQuery(
    ["getValidateIsFilledProfile", nameId],
    () => axiosClient.post(`/User/ValidateIsFilledProfile`, nameId),
    { enabled: !!nameId }
  );

  return data;
};
export default useGetValidateIsFilledProfile;
