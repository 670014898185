import React from "react";
import "antd/dist/reset.css";
import { Provider } from "react-redux";
import { Toaster, ToastBar, toast } from "react-hot-toast";
import { createRoot } from "react-dom/client";
import { QueryClient, QueryClientProvider } from "react-query";
import { ThemeProvider } from "styled-components";
import { AppRouter } from "./pages/App.router";
import { store } from "./core/store/index";
import { theme } from "./styles/theme";
import { Link } from "./components/Link";
import { ErrorAlertIcon, SuccessAlertIcon } from "./assets/icons";
import { Div } from "./styles/Common";
import { useMediaQuery } from "react-responsive";

const ToastWrapper = () => {
  const isMobile = useMediaQuery({ query: "(max-width: 768px)" });

  return (
    <Toaster
      position="top-right"
      reverseOrder={true}
      containerStyle={{ zIndex: 999999 }}
      toastOptions={{
        duration: 3000,
        success: {
          icon: (
            <Div
              align="center"
              style={{
                background: theme.colors.alerts.success,
                borderRadius: "100%",
                padding: "6px",
              }}
            >
              <SuccessAlertIcon />
            </Div>
          ),
          style: {
            background: theme.colors.white,
          },
        },
        error: {
          icon: (
            <Div
              align="center"
              style={{
                background: theme.colors.alerts.error,
                borderRadius: "100%",
                padding: "6px",
              }}
            >
              <ErrorAlertIcon />
            </Div>
          ),
          style: {
            background: theme.colors.white,
          },
        },
        loading: {
          iconTheme: {
            primary: theme.colors.gray50,
            secondary: theme.colors.gray500,
          },
          style: {
            background: theme.colors.white,
          },
        },
        style: {
          minWidth: !isMobile ? "374px" : "100%",
          zIndex: 999999,
          color: theme.colors.gray800,
          fontWeight: theme.fonts.weight.regular,
          fontSize: theme.fonts.size.default,
          fontFamily: theme.fonts.family,
          display: "flex",
          alignItems: "center",
        },
      }}
    >
      {(t) => (
        <ToastBar toast={t}>
          {({ icon, message }) => (
            <>
              {icon}
              {message}
              {t.type !== "loading" && (
                <Link height="100%" onClick={() => toast.dismiss(t.id)} />
              )}
            </>
          )}
        </ToastBar>
      )}
    </Toaster>
  );
};

const root = createRoot(document.getElementById("root"));
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

root.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <Provider store={store}>
        <ToastWrapper />
        <ThemeProvider theme={theme}>
          <AppRouter />
        </ThemeProvider>
      </Provider>
    </QueryClientProvider>
  </React.StrictMode>
);
