import { createSlice } from "@reduxjs/toolkit";
import { axiosClient } from "../../services/axiosInstance";

export const slice = createSlice({
  name: "varificationCodes",
  initialState: {
    verificationTokens: {},
  },
  reducers: {
    setVerificationTokens: (state, action) => {
      state.verificationTokens = action.payload;
    },
  },
});

export default slice.reducer;

export const { setVerificationTokens } = slice.actions;
