import styled from "styled-components";
import backImage from "../../assets/images/backgroundHome.png";

export const PageWrapper = styled.div`
  display: flex;
  min-height: 100vh;
  width: 100%;
  background: url(${backImage}) center/cover no-repeat;
  flex-direction: column;
  align-items: center;
  padding: ${(props) =>
    props.isMobile ? "0 25px 48px 25px" : "0 98px 48px 98px"};
`;
