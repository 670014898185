import { BrowserRouter, Routes, Route } from "react-router-dom";
import { PublicRoute } from "../components/PublicRoute/PublicRoute";

import { App } from "./App";
import { Login } from "./Login";
import { ResetPassword } from "./ResetPassword";
import { Home } from "./Home";
import { PlatForm } from "./Platform";
import { UserRegister } from "./UserRegister";
import { Insights } from "./Insights";
import { ContactUs } from "./ContactUs";
import { ProfileInfo } from "./ProfileInfo";
import { ResetPasswordPlatform } from "./ResetPasswordPlatform";

export const AppRouter = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<App />}>
          <Route
            index
            element={
              <PublicRoute>
                <Home />
              </PublicRoute>
            }
          />

          <Route
            path="reset-password"
            element={
              <PublicRoute>
                <ResetPassword />
              </PublicRoute>
            }
          />

          <Route
            path="login"
            element={
              <PublicRoute>
                <Login />
              </PublicRoute>
            }
          />
          <Route
            path="user-register"
            element={
              <PublicRoute>
                <UserRegister />
              </PublicRoute>
            }
          />
          <Route
            path="insights"
            element={
              <PublicRoute>
                <Insights />
              </PublicRoute>
            }
          />

          <Route
            path="contactUs"
            element={
              <PublicRoute>
                <ContactUs />
              </PublicRoute>
            }
          />

          <Route path="platform" element={<PlatForm />} />
          <Route path="profileInfo" element={<ProfileInfo />} />
          <Route
            path="ResetPasswordPlatform"
            element={<ResetPasswordPlatform />}
          />
        </Route>
      </Routes>
    </BrowserRouter>
  );
};
