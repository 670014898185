import { useMutation } from "react-query";
import { axiosClient } from "../../../core/services/axiosInstance";

const useSendSmsOtp = () => {
  const mutation = useMutation(({ phoneNumber, isPasswordReset }) => {
    const requestBody = { phoneNumber, isPasswordReset };
    return axiosClient.post("Authentication/SendSmsOTP", requestBody);
  });
  return mutation;
};
export default useSendSmsOtp;
