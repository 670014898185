import React from "react";
import { Row, Text } from "../../styles/Common";
import { CopyrightIcon } from "../../assets/icons";
import { theme } from "../../styles/theme";
import { useOutletContext } from "react-router-dom";

export const CopywriteFooter = () => {
  const { isMobile } = useOutletContext();
  return (
    <Row
      justify="center"
      align={!isMobile && "center"}
      width={!isMobile ? "100%" : "90%"}
      style={{ zIndex: 1, position: "absolute", bottom: "5px" }}
      gap={isMobile && "10px"}
    >
      <CopyrightIcon width={"20px"} height={"20px"} />
      <Text
        size={theme.fonts.size.sm}
        color={theme.colors.gray200}
        style={{ lineHeight: isMobile && "19px" }}
      >
        2024 Pulpey. Diseñado por Mely Rave. Todos los derechos reservados
      </Text>
    </Row>
  );
};
