import { axiosClient } from "../core/services/axiosInstance";
import { jwtDecode } from "jwt-decode";

const CryptoJS = require("crypto-js");

export const encrypt = (message) => {
  const key = process.env.REACT_APP_CRYPT_KEY;
  const iv = process.env.REACT_APP_CRYPT_IV;

  const encryptedData = CryptoJS.AES.encrypt(
    message,
    CryptoJS.enc.Utf8.parse(key),
    {
      iv: CryptoJS.enc.Utf8.parse(iv),
      keySize: 256,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7,
    }
  );

  return encryptedData.toString();
};

export const decrypt = (encryptedMessage) => {
  const key = process.env.REACT_APP_CRYPT_KEY;
  const iv = process.env.REACT_APP_CRYPT_IV;

  const decryptedData = CryptoJS.AES.decrypt(
    encryptedMessage,
    CryptoJS.enc.Utf8.parse(key),
    {
      iv: CryptoJS.enc.Utf8.parse(iv),
      keySize: 256,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7,
    }
  );

  return decryptedData.toString(CryptoJS.enc.Utf8);
};

const setLocalUser = (user) => {
  localStorage.setItem("user", JSON.stringify(user));
};

export const getToken = () => {
  const user = localStorage.getItem("user");

  return user;
};
export const getUserInfo = () => {
  const user = localStorage.getItem("user")
    ? jwtDecode(localStorage.getItem("user"))
    : {
        nameid: "",
        unique_name: "",
        email: "",
        role: "",
        nbf: null,
        exp: null,
        iat: null,
      };
  return user;
};

export const getLabelById = (id, options) => {
  const item = options.find((option) => option.value === id);
  return item ? item.label : null;
};

export const getLogo = () => {
  const logo = localStorage.getItem("urlLogo")
    ? localStorage.getItem("urlLogo")
    : null;
  return logo;
};

export const isSuperAdmin = () => {
  const user = localStorage.getItem("user")
    ? jwtDecode(localStorage.getItem("user"))
    : {
        rol: "",
      };
  return user.rol === "SuperAdmin";
};

export const isAdmin = () => {
  const user = localStorage.getItem("user")
    ? jwtDecode(localStorage.getItem("user"))
    : {
        rol: "",
      };
  return user.rol === "Admin";
};

export const isRegular = () => {
  const user = localStorage.getItem("user")
    ? jwtDecode(localStorage.getItem("user"))
    : {
        rol: "",
      };
  return user.rol === "User";
};

export const userClinic = () => {
  const user = localStorage.getItem("user")
    ? jwtDecode(localStorage.getItem("user"))
    : {
        clinic: "",
        id: "",
      };
  return user.clinic;
};

export const uploadFiles = async (file, url) => {
  const formData = new FormData();
  formData.append("File", file);
  return axiosClient.post(url, formData);
};

export const convertFileToBase64 = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.onload = (event) => {
      const base64String = event.target.result.split(",")[1];
      resolve(base64String);
    };

    reader.onerror = (error) => {
      reject(error);
    };

    reader.readAsDataURL(file);
  });
};

export const createURLFromBase64 = (base64String, mimeType) => {
  const byteCharacters = atob(base64String);
  const byteArray = new Uint8Array(byteCharacters.length);

  for (let i = 0; i < byteCharacters.length; i++) {
    byteArray[i] = byteCharacters.charCodeAt(i);
  }

  const blob = new Blob([byteArray], { type: mimeType });
  const url = URL.createObjectURL(blob);

  return url;
};

export const download = async (url, filename, instance, token) =>
  instance
    .post(
      url,
      {},
      {
        responseType: "blob",
        headers: { Authorization: token },
      }
    )
    .then(
      (response) => {
        const pdfUrl = window.URL.createObjectURL(new Blob([response.data]));
        const a = document.createElement("a");
        a.href = pdfUrl;
        a.setAttribute("download", `${filename}`);
        document.body.appendChild(a);
        a.click();
        a.remove();
      },
      (e) => {
        return Promise.reject(e);
      }
    );

export const downloadFile = async (url, filename, instance, token) =>
  instance
    .get(url, {
      responseType: "blob",
      headers: { Authorization: token },
    })
    .then((response) => {
      const pdfUrl = window.URL.createObjectURL(new Blob([response.data]));
      const a = document.createElement("a");
      a.href = pdfUrl;
      a.setAttribute("download", `${filename}`);
      document.body.appendChild(a);
      a.click();
      a.remove();
    })
    .catch((error) => {
      return Promise.reject(error);
    });

export const dateFormat = (value) => {
  const d = new Date(value);
  let month = d.getMonth() + 1;
  let day = d.getDate();
  const year = d.getFullYear();

  if (month.toString().length < 2) month = `0${month}`;
  if (day.toString().length < 2) day = `0${day}`;

  return [day, month, year].join("/");
};

export const compareArrays = (array1, array2) => {
  if (array1.length !== array2.length) {
    return false;
  }

  const sortedArray1 = _.sortBy(array1, (obj) =>
    JSON.stringify(_.omit(obj, "key"))
  );
  const sortedArray2 = _.sortBy(array2, (obj) =>
    JSON.stringify(_.omit(obj, "key"))
  );

  for (let i = 0; i < sortedArray1.length; i++) {
    const obj1 = sortedArray1[i];
    const obj2 = sortedArray2[i];
    if (!_.isEqual(_.omit(obj1, "key"), _.omit(obj2, "key"))) {
      return false;
    }
  }

  return true;
};

export const parseLength = (lengthStr) => {
  if (lengthStr !== null) {
    if (lengthStr.includes("'")) {
      const parts = lengthStr.split("'");
      const feet = parseInt(parts[0]);
      const inches = parseFloat(parts[1].replace('"', ""));
      return feet * 12 + inches;
    } else {
      const parts = lengthStr.split("-");
      const feet = parseInt(parts[0]);
      const inches = parseInt(parts[1].split(" ")[0]);
      const fraction = parts[1].split(" ")[1] || "0/1";
      const fractionParts = fraction.split("/");
      const numerator = parseInt(fractionParts[0]);
      const denominator = parseInt(fractionParts[1]) || 1;
      const decimal = numerator / denominator;
      return feet * 12 + inches + decimal;
    }
  }
};

export const formatLength = (lengthInches) => {
  if (lengthInches) {
    const feet = Math.floor(lengthInches / 12);
    const inches = Math.round(lengthInches % 12);
    const fraction = lengthInches % 1;
    const denominator = 16;
    const numerator = Math.round(fraction * denominator);
    const fractionStr = `${numerator}/${denominator}`;
    return `${feet}'${inches} ${fractionStr}"`;
  }
};

export const commify = (n) => {
  var parts = n.toString().split(",");
  const numberPart = parts[0];
  const decimalPart = parts[1];
  const thousands = /\B(?=(\d{3})+(?!\d))/g;
  return (
    numberPart.replace(thousands, ",") + (decimalPart ? "." + decimalPart : "")
  );
};

export const formatPriceToDollar = (price) => {
  return price.toLocaleString("en-US", {
    style: "currency",
    currency: "USD",
  });
};

export const nameLength = 100;
export const emailLength = 100;
export const phoneLength = 15;
export const passwordLength = 16;
