import { useQuery } from "react-query";
import { axiosClient } from "../services/axiosInstance";

const useGetRole = () => {
  const data = useQuery(["getRole"], () =>
    axiosClient.post(`Setting/GepSettingList?groupSettingId=4`)
  );
  return data;
};
export default useGetRole;
