import { useQuery } from "react-query";
import { axiosClient } from "../services/axiosInstance";

const useGetProfessions = () => {
  const data = useQuery(["getProfessions"], () =>
    axiosClient.post(`Setting/GepSettingList?groupSettingId=8`)
  );
  return data;
};
export default useGetProfessions;
