import { useQuery } from "react-query";
import { axiosClient } from "../services/axiosInstance";

const useGetDepartmentList = () => {
  const data = useQuery(["getCityList"], () =>
    axiosClient.get(`Department/GetDepartmentList`)
  );

  return data;
};
export default useGetDepartmentList;
