import { Outlet } from "react-router-dom";
import "../styles/App.css";
import { useAuth } from "../core/hooks/useAuth";

import { useMediaQuery } from "react-responsive";

const App = () => {
  const { isAuth } = useAuth();
  const isMobile = useMediaQuery({
    query: `(max-width: 768px)`,
  });
  return (
    <section id="app" className="App">
      <main className="main">
        <Outlet context={{ isMobile }} />
      </main>
    </section>
  );
};

export { App };
