import styled from "styled-components";
import { theme } from "../../styles/theme";
import backImage from "../../assets/images/bgLogin.png";

export const LoginWrapper = styled.div`
  display: flex;
  min-height: 100vh;
  width: 100%;
  background: transparent url(${backImage}) 0% 0% no-repeat padding-box;
  background-position: center;
  background-size: cover;
  flex-direction: column;
  padding: ${(props) =>
    props.isMobile ? "0 20px 25px 20px" : "0 97px 25px 0"};
`;
