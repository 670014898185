import styled from "styled-components";
import { Collapse } from "antd";
import { theme } from "../../styles/theme";

export const CustomCollapse = styled(Collapse)`
  .ant-collapse {
    border-radius: ${({ group }) => (group ? "0px" : "15px")} !important;
    box-shadow: ${({ group }) => (group ? "0" : "0px 7px 26px #0000000B")};
    width: ${({ width }) => width || "100%"} !important;
  }

  /* Estilizar cada item de Collapse */
  .ant-collapse-item {
    position: relative;
    background-color: ${({ background }) =>
      background || theme.colors.blue50} !important;
    border-radius: ${({ group }) => (group ? "0px" : "15px")} !important;
    border: none !important;
  }

  /* Estilizar el contenido del Collapse */
  .ant-collapse-content {
    border-radius: 0 0 15px 15px !important;
    border: 1px solid ${theme.colors.blue50};
    background-color: ${({ background }) =>
      background || theme.colors.blue50} !important;
    padding-bottom: 5px;
  }

  .ant-collapse-content-box {
    padding: 16px 16px 5px 16px !important;
    background-color: ${({ background }) =>
      background || theme.colors.blue50} !important;
  }

  /* Estilizar el encabezado del Collapse */
  .ant-collapse-header {
    align-items: center;
    border-radius: ${({ group }) => (group ? "0px" : "15px")} !important;
    height: ${({ headerHeight }) => headerHeight};
    padding: 0px 12px 15px 12px !important;
    border-bottom: ${`1px solid ${theme.colors.gray100}`};

    .ant-collapse-header-text {
      font-family: ${theme.fonts.family};
      font-size: ${({ fontSize }) => fontSize || "18px"} !important;
      font-weight: ${({ fontWeight }) => fontWeight || theme.fonts.weight.bold};
      color: ${({ fontColor }) => fontColor || theme.colors.white};
    }

    .ant-collapse-arrow {
      font-size: 14px;
    }
  }
`;
