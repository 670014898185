import React, { useState, useEffect, useCallback } from "react";
import { useForm, Controller } from "react-hook-form";
import { useQueryClient } from "react-query";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import {
  GoogleMap,
  MarkerF,
  useLoadScript,
  Autocomplete,
} from "@react-google-maps/api";
import { yupResolver } from "@hookform/resolvers/yup";
import toast from "react-hot-toast";
import { Div, Text, Row, Col } from "../../../styles/Common";
import { theme } from "../../../styles/theme";
import { Button } from "../../../components/Button";
import { Select } from "../../../components/Select";
import { Input } from "../../../components/Input";
import { PinIcon } from "../../../assets/icons";
import { VerifyIcon } from "../../../assets/icons";
import { validateProfileInfoStep1 } from "../../../utilities/validations";
import useGetDepartmentList from "../../../core/hooks/useGetDepartmentList";
import useGetCityList from "../../../core/hooks/useGetCityList";
import useSaveUserProfile from "../hooks/useSaveUserProfile";
import { setVerificationTokens } from "../../../core/store/verificationCodes";
import useSendEmailOtp from "../../Login/hooks/useSendEmailOtp";
import useSendSmsOtp from "../../Login/hooks/useSendSmsOtp";
import { getUserInfo, getLabelById } from "../../../utilities/helpers";
import useValidateUserExistByEmail from "../../Platform/hooks/useValidateUserExistByEmail";
import useValidateUserExistByPhoneNumber from "../../Platform/hooks/useValidateUserExistByPhoneNumber";

export const Step1 = ({
  formStep1,
  setFormStep1,
  dataInitial,
  isVerifiedEmail,
  setIsVerifiedEmail,
  isVerifiedPhone,
  setIsVerifiedPhone,
  setShowModalOTP,
  setStepNumber,
  optionsCodes,
  isMobile,
}) => {
  const saveUserProfile = useSaveUserProfile();
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const {
    control,
    handleSubmit,
    watch,
    reset,
    setValue,
    formState: { errors },
  } = useForm({
    mode: "onBlur",
    resolver: yupResolver(validateProfileInfoStep1),
  });

  const apiKey = process.env.REACT_APP_GOOGLE_API_KEY;

  const sendEmailOtp = useSendEmailOtp();
  const sendSmsOtp = useSendSmsOtp();
  const validateUserExistByEmail = useValidateUserExistByEmail();
  const validateUserExistByPhoneNumber = useValidateUserExistByPhoneNumber();

  const tokenRequested = useSelector(
    (state) => state.verificationCodes?.verificationTokens
  );

  const [isCompletedForm, setIsCompletedForm] = useState(false);
  const [coordinates, setCoordinates] = useState({
    lat: 14.6349149,
    lng: -90.5068824,
  });
  const [mapFirstLoad, setMapFirstLoad] = useState(true);

  const { unique_name } = getUserInfo();

  const watchedFields = watch([
    "ind",
    "phone",
    "email",
    "department",
    "municipality",
    "address",
    "maps",
    "instruction",
    "zone",
  ]);
  useEffect(() => {
    if (watchedFields[2] !== dataInitial.email) {
      setIsVerifiedEmail(false);
    } else {
      setIsVerifiedEmail(true);
    }
    if (watchedFields[1] !== dataInitial.phone) {
      setIsVerifiedPhone(false);
    } else {
      setIsVerifiedPhone(true);
    }
  }, [watchedFields[1], watchedFields[2], dataInitial]);

  const getDepartmentList = useGetDepartmentList();
  const { data: dataDepartments, isSuccess: isSuccessDepartments } =
    getDepartmentList;
  const [departmentOptions, setDepartmentOptions] = useState([]);
  useEffect(() => {
    if (isSuccessDepartments)
      setDepartmentOptions(
        dataDepartments?.data?.result.map((item) => ({
          label: item.departmentName,
          value: item.id,
        }))
      );
  }, [dataDepartments]);

  const getCityList = useGetCityList(watchedFields[3]);
  const { data: dataCity, isSuccess: isSuccessDataCity } = getCityList;
  const [cityOptions, setCityOptions] = useState([]);
  useEffect(() => {
    if (isSuccessDataCity)
      setCityOptions(
        dataCity?.data?.result.map((item) => ({
          label: item.cityName,
          value: item.id,
        }))
      );
  }, [dataCity]);

  useEffect(() => {
    reset({
      address: formStep1.address,
      department: formStep1.department,
      email: formStep1.email,
      ind: formStep1.ind,
      municipality: formStep1.municipality,
      phone: formStep1.phone,
      instruction: formStep1.instruction,
      maps: formStep1.exactAddress,
      zone: formStep1.zone,
    });
    if (formStep1.latitude && formStep1.longitude) {
      setCoordinates({
        lat: formStep1.latitude,
        lng: formStep1.longitude,
      });
    }
  }, [formStep1]);

  const { isLoaded } = useLoadScript({
    googleMapsApiKey: apiKey,
    libraries: ["places"],
  });

  useEffect(() => {
    const isCompleted = watchedFields.every((item, index) => {
      if (index === 7) return true;
      return item !== "" && item !== undefined && item !== null;
    });
    setIsCompletedForm(isCompleted);
  }, [watchedFields]);

  useEffect(() => {
    if (watchedFields[3] !== formStep1.department) {
      setValue("municipality", null);
      queryClient.invalidateQueries(["getCityList", watchedFields[3]]);
    }
  }, [watchedFields[3]]);
  useEffect(() => {
    if (mapFirstLoad) {
      setMapFirstLoad(false);
      return;
    }
    const city = getLabelById(watchedFields[4], cityOptions);
    const department = getLabelById(watchedFields[3], departmentOptions);

    if (city && department) {
      const fetchCoordinates = async () => {
        const address = `${city}, ${department}`;
        const response = await fetch(
          `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(
            address
          )}&key=${apiKey}`
        );
        const data = await response.json();
        if (data.results.length > 0) {
          const { lat, lng } = data.results[0].geometry.location;
          setCoordinates({ lat, lng });
        }
      };

      fetchCoordinates();
    }
  }, [watchedFields[3], watchedFields[4]]);

  const handleMarkerDragEnd = async (event) => {
    const { lat, lng } = event.latLng.toJSON();
    setCoordinates({ lat, lng });

    const response = await fetch(
      `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=${apiKey}`
    );
    const data = await response.json();
    if (data.results.length > 0) {
      setValue("maps", data.results[0].formatted_address);
    }
  };

  const updateCoordinates = async ({ lat, lng }) => {
    setCoordinates({ lat, lng });

    const response = await fetch(
      `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=${apiKey}`
    );
    const data = await response.json();
    if (data.results.length > 0) {
      setValue("maps", data.results[0].formatted_address);
    }
  };

  const [autocomplete, setAutocomplete] = useState(null);

  const onLoad = useCallback((autocompleteInstance) => {
    setAutocomplete(autocompleteInstance);
  }, []);

  const onPlaceChanged = useCallback(() => {
    if (autocomplete) {
      const place = autocomplete.getPlace();
      if (place.geometry) {
        const newCoordinates = {
          lat: place.geometry.location.lat(),
          lng: place.geometry.location.lng(),
        };
        updateCoordinates(newCoordinates);
      }
    }
  }, [autocomplete, updateCoordinates]);

  const handleContact = async (data) => {
    if (!isVerifiedEmail || !isVerifiedPhone) {
      const emailAction = !isVerifiedEmail ? sendEmailOtp : null;
      const phoneAction = !isVerifiedPhone ? sendSmsOtp : null;

      const emailData = !isVerifiedEmail
        ? { email: data.email, userName: unique_name, isPasswordReset: false }
        : null;
      const phoneData = !isVerifiedPhone
        ? {
            phoneNumber: `${getLabelById(Number(data.ind), optionsCodes)}${
              data.phone
            }`,
            isPasswordReset: false,
          }
        : null;

      const handleSuccess = (res, type) => {
        if (type === "email") {
          dispatch(
            setVerificationTokens({
              tokenEmail: res?.data?.result,
              tokenPhone: tokenRequested?.tokenPhone,
            })
          );
        } else if (type === "phone") {
          dispatch(
            setVerificationTokens({
              tokenEmail: tokenRequested?.tokenEmail,
              tokenPhone: res?.data?.result,
            })
          );
        }
      };

      const checkEmail = async () => {
        try {
          const res = await validateUserExistByEmail.mutateAsync({
            userId: Number(formStep1.userId),
            userEmail: data.email,
          });
          if (res?.data.result) {
            toast.error("Correo ya registrado");
            return false;
          }
          return true;
        } catch (err) {
          toast.error(err?.response?.data?.title || err?.response?.data);
          return false;
        }
      };

      const checkPhone = async () => {
        try {
          const res = await validateUserExistByPhoneNumber.mutateAsync({
            userId: Number(formStep1.userId),
            phoneNumber: data.phone,
          });
          if (res?.data.result) {
            toast.error("Teléfono ya registrado");
            return false;
          }
          return true;
        } catch (err) {
          toast.error(err?.response?.data?.title || err?.response?.data);
          return false;
        }
      };

      try {
        const [emailAvailable, phoneAvailable] = await Promise.all([
          checkEmail(),
          checkPhone(),
        ]);

        if (emailAvailable && phoneAvailable) {
          if (emailAction) {
            emailAction.reset();
            await emailAction.mutateAsync(emailData, {
              onSuccess: (res) => handleSuccess(res, "email"),
              onError: (err) => {
                toast.error(err?.response?.data?.title || err?.response?.data);
              },
            });
          }

          if (phoneAction) {
            phoneAction.reset();
            await phoneAction.mutateAsync(phoneData, {
              onSuccess: (res) => handleSuccess(res, "phone"),
              onError: (err) => {
                toast.error(
                  err?.response?.data?.title ||
                    err?.response?.data ||
                    err?.message
                );
              },
            });
          }
          setShowModalOTP(true);
          setFormStep1({
            ...formStep1,
            email: data.email,
            phone: data.phone,
            ind: Number(data.ind),
            department: Number(data.department),
            municipality: Number(data.municipality),
            address: data.address,
            exactAddress: data.maps,
            instruction: data.instruction,
          });
        }
      } catch (err) {
        toast.error("Hubo un error al verificar los datos. Intente de nuevo.");
      }
    } else {
      saveUserProfile.reset();
      saveUserProfile.mutate(
        {
          userId: Number(formStep1.userId),
          maritalStatusId: null,
          genderId: null,
          roleHouseId: null,
          cityId: Number(data.municipality),
          address: data.address,
          instruction: data.instruction,
          zone: data.zone,
          stepNumber: 1,
          phoneNumber: data.phone,
          name: null,
          email: data.email,
          lastName: null,
          birthDate: null,
          phoneNumberCode: Number(data.ind),
          departmentId: Number(data.department),
          exactAddress: data.maps,
          latitude: String(coordinates.lat),
          longitude: String(coordinates.lng),
        },
        {
          onSuccess: () => {
            setMapFirstLoad(true);
            queryClient.invalidateQueries(["getUserProfile"]);
            setStepNumber(2);
          },
          onError: (err) => {
            if (err?.response?.data?.errors) {
              const errorMessages = Object.values(err.response.data.errors)
                .flat()
                .join(", ");
              toast.error(errorMessages);
            }
          },
        }
      );
    }
  };

  return (
    <>
      <Div gap="15px" m="0 0 42px 0" direction={!isMobile ? "row" : "column"}>
        <Row gap={!isMobile ? "15px" : "5px"}>
          <Controller
            name="ind"
            control={control}
            render={({ field }) => (
              <Select
                label="Indicativo"
                color={theme.colors.gray200}
                border={theme.colors.white200}
                options={optionsCodes}
                width={"79px"}
                height="45px"
                style={{ padding: "0px 2px" }}
                background={theme.colors.blue100}
                error={errors?.ind}
                helper={errors?.ind?.message}
                {...field}
              />
            )}
          />
          <Controller
            name="phone"
            control={control}
            render={({ field }) => (
              <Input
                label={"Número de teléfono"}
                colorLabel={theme.colors.gray200}
                sizeLabel={theme.fonts.size.default}
                width={!isMobile ? "399.7px" : "100%"}
                error={errors.phone}
                helper={errors.phone ? errors.phone.message : ""}
                {...field}
              />
            )}
          />
        </Row>
        <Controller
          name="email"
          control={control}
          render={({ field }) => (
            <Input
              label={"Correo electrónico"}
              colorLabel={theme.colors.gray200}
              sizeLabel={theme.fonts.size.default}
              width={!isMobile ? "333.2px" : "100%"}
              error={errors.email}
              helper={errors.email ? errors.email.message : ""}
              {...field}
              onChange={(e) => {
                field.onChange(e.target.value.toLowerCase());
              }}
            />
          )}
        />
        {isVerifiedEmail && isVerifiedPhone && (
          <Div
            background={theme.colors.green100}
            radius="20px"
            width="141px"
            height="40px"
            align="center"
            justify="center"
            m={!isMobile ? "27px 0 0 0" : "0 0 0 0"}
            border={`1px solid ${theme.colors.green300}`}
            gap="10px"
          >
            <VerifyIcon stroke={theme.colors.green300} />
            <Text color={theme.colors.green300}>Verificado</Text>
          </Div>
        )}
      </Div>
      <Div
        gap="15px"
        m={!isMobile ? "0 0 32px 0" : "0"}
        direction={!isMobile ? "row" : "column"}
      >
        <Controller
          name="department"
          control={control}
          render={({ field }) => (
            <Select
              label={"Departamento"}
              width={!isMobile ? "493.2px" : "100%"}
              height={"45px"}
              options={departmentOptions}
              background={theme.colors.blue100}
              error={errors.department}
              helper={errors.department ? errors.department.message : ""}
              {...field}
            />
          )}
        />
        <Controller
          name="municipality"
          control={control}
          render={({ field }) => (
            <Select
              label={"Municipio"}
              width={!isMobile ? "493.2px" : "100%"}
              disabled={!watchedFields[3]}
              height={"45px"}
              options={cityOptions}
              background={theme.colors.blue100}
              error={errors.municipality}
              helper={errors.municipality ? errors.municipality.message : ""}
              {...field}
            />
          )}
        />
      </Div>
      <Div gap="12px" m="0 0 32px 0" direction={!isMobile ? "row" : "column"}>
        <Div
          width={!isMobile ? "506px" : "100%"}
          height={!isMobile ? "310px" : "210px"}
          background={theme.colors.white}
          radius="22px"
          m="20px 0 0 0"
          style={{ overflow: "hidden" }}
        >
          {isLoaded && (
            <GoogleMap
              mapContainerStyle={{ height: "310px", width: "506px" }}
              center={
                coordinates.lat !== 0 && coordinates.lng !== 0
                  ? coordinates
                  : { lat: 14.6349149, lng: -90.5068824 }
              }
              zoom={15}
              options={{
                streetViewControl: false,
                mapTypeControl: false,
                fullscreenControl: false,
                zoomControl: false,
              }}
              onClick={handleMarkerDragEnd}
            >
              <Controller
                name="maps"
                control={control}
                render={({ field }) => (
                  <Autocomplete onLoad={onLoad} onPlaceChanged={onPlaceChanged}>
                    <Input
                      placeholder={"Ingresa tu dirección exacta"}
                      colorLabel={theme.colors.gray200}
                      sizeLabel={theme.fonts.size.default}
                      border={theme.colors.gray200}
                      style={{ padding: "0px 20px", margin: "7.5px 9.5px" }}
                      width={!isMobile ? "440px" : "100%"}
                      background={theme.colors.white}
                      error={errors.maps}
                      helper={errors.maps ? errors.maps.message : ""}
                      suffix={
                        <PinIcon
                          stroke={theme.colors.gray200}
                          width={"24px"}
                          height={"24px"}
                        />
                      }
                      {...field}
                    />
                  </Autocomplete>
                )}
              />
              <MarkerF
                position={coordinates}
                draggable={true}
                onDragEnd={handleMarkerDragEnd}
              />
            </GoogleMap>
          )}
        </Div>
        <Col width={!isMobile ? "493px" : "100%"} gap="6px">
          <Controller
            name="address"
            control={control}
            render={({ field }) => (
              <Input
                label={"Dirección"}
                colorLabel={theme.colors.gray200}
                sizeLabel={theme.fonts.size.default}
                style={{ padding: "0px 20px" }}
                width={!isMobile ? "493px" : "100%"}
                background={theme.colors.blue100}
                error={errors.address}
                helper={errors.address ? errors.address.message : ""}
                suffix={
                  <PinIcon
                    stroke={theme.colors.white}
                    width={"24px"}
                    height={"24px"}
                  />
                }
                {...field}
              />
            )}
          />
          <Text
            size={theme.fonts.size.sm}
            color={theme.colors.gray200}
            style={{ padding: "0 10px" }}
            mb="15px"
          >
            Ejem: Especificar zona, piso, apartamento o número. En caso de no
            encontrar tu dirección en el mapa, escribe tu dirección completa
            aquí.
          </Text>

          <Controller
            name="instruction"
            control={control}
            render={({ field }) => (
              <Input
                label={"Indicaciones"}
                colorLabel={theme.colors.gray200}
                sizeLabel={theme.fonts.size.default}
                style={{ padding: "0px 20px" }}
                width={!isMobile ? "493px" : "100%"}
                background={theme.colors.blue100}
                {...field}
              />
            )}
          />
          <Text
            size={theme.fonts.size.sm}
            color={theme.colors.gray200}
            style={{ padding: "0 10px" }}
            mb="15px"
          >
            Tú información de residencia es muy importante para que podamos
            enviarte la PulpeyBox a la puerta de tu casa.
          </Text>
          <Controller
            name="zone"
            control={control}
            render={({ field }) => (
              <Input
                label={"Zona"}
                colorLabel={theme.colors.gray200}
                sizeLabel={theme.fonts.size.default}
                style={{ padding: "0px 20px" }}
                width={!isMobile ? "493px" : "100%"}
                background={theme.colors.blue100}
                inputMode="numeric"
                maxLength={2}
                min={0}
                error={errors.zone}
                helper={errors.zone ? errors.zone.message : ""}
                onInput={(e) => {
                  const inputValue = e.target.value;
                  const filteredValue = inputValue.replace(/[^0-9]/g, "");
                  if (filteredValue.length <= 2) {
                    e.target.value = filteredValue;
                  } else {
                    e.target.value = filteredValue.slice(0, 2);
                  }
                }}
                {...field}
              />
            )}
          />
        </Col>
      </Div>
      <form onSubmit={handleSubmit(handleContact)}>
        <Button
          width={"146px"}
          htmlType="submit"
          disabled={!isCompletedForm}
          loading={
            saveUserProfile.isLoading ||
            sendEmailOtp.isLoading ||
            sendSmsOtp.isLoading ||
            validateUserExistByEmail.isLoading ||
            validateUserExistByPhoneNumber.isLoading
          }
        >
          Continuar
        </Button>
      </form>
    </>
  );
};

Step1.propTypes = {
  formStep1: PropTypes.object,
  setFormStep1: PropTypes.func,
  dataInitial: PropTypes.object,
  isVerifiedEmail: PropTypes.bool,
  setIsVerifiedEmail: PropTypes.func,
  isVerifiedPhone: PropTypes.bool,
  setIsVerifiedPhone: PropTypes.func,
  setShowModalOTP: PropTypes.func,
  setStepNumber: PropTypes.func,
  optionsCodes: PropTypes.array,
  isMobile: PropTypes.bool,
};
