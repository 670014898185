import React, { useState, useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useSelector } from "react-redux";
import { Text, Col, Div, Row } from "../../styles/Common";
import { theme } from "../../styles/theme";
import { Button } from "../../components/Button";
import { Card } from "../../components/Card";
import pulpey from "../../assets/images/pulpey.svg";
import { Password } from "../../components/Password";
import mobile from "../../assets/images/Mobile.png";
import { validateResetPassword } from "../../utilities/validations";
import toast from "react-hot-toast";
import { encrypt } from "../../utilities/helpers";
import { useNavigate } from "react-router-dom";
import { PageWrapper } from "../Platform/styles";
import useResetPassword from "./hooks/useResetPassword";
import ModalSuccess from "./components/ModalSuccess";
import { CopywriteFooter } from "../../components/CopywriteFooter";
import { CheckIcon } from "../../assets/icons";
import { useOutletContext } from "react-router-dom";

export const ResetPassword = () => {
  const resetPassword = useResetPassword();
  const { isMobile } = useOutletContext();

  const navigate = useNavigate();
  const {
    handleSubmit,
    control,
    watch,
    formState: { errors, isValid },
  } = useForm({
    mode: "onBlur",
    resolver: yupResolver(validateResetPassword),
  });

  const tokenRequested = useSelector((state) => state.auth.tokenRequested);

  useEffect(() => {
    if (!tokenRequested?.dataToSend) {
      navigate("/login");
    }
  }, [tokenRequested]);

  const [showModal, setShowModal] = useState(false);
  const handleCloseModal = () => {
    setShowModal(false);
  };
  const [statusPassword, setStatusPassword] = useState({
    hasUppercase: false,
    hasNumber: false,
    hasSpecialChars: false,
    length: false,
    equalPassword: false,
  });
  const password = watch("password");
  const confirmPassword = watch("confirmPassword");

  useEffect(() => {
    const checkPasswordStrength = (pwd) => {
      if (!pwd) {
        return {
          hasUppercase: false,
          hasNumber: false,
          hasSpecialChars: false,
          length: false,
          equalPassword: false,
        };
      }
      return {
        length: pwd.length >= 8,
        hasUppercase: /[A-Z]/.test(pwd),
        hasNumber: /\d/.test(pwd),
        hasSpecialChars: /[#?!@$%^&*.\-]/.test(pwd),
        equalPassword: pwd === confirmPassword,
      };
    };

    const result = checkPasswordStrength(password);
    setStatusPassword(result);
  }, [password, confirmPassword]);

  const passwordInfo = [
    { name: "Mínimo 8 caracteres", key: "length" },
    { name: "Incluir al menos una mayúscula", key: "hasUppercase" },
    { name: "Incluir números", key: "hasNumber" },
    {
      name: "Incluir al menos un carácter especial como: .#?!@$%^&*-",
      key: "hasSpecialChars",
    },
    {
      name: "Las contraseñas coinciden",
      key: "equalPassword",
    },
  ];
  const handleResetPasword = (data) => {
    const dataResetPassword = {
      password: encrypt(data?.password),
      passwordConfirm: encrypt(data?.confirmPassword),
      [tokenRequested?.recoveryMethod === "phone" ? "phoneNumber" : "email"]:
        tokenRequested?.recoveryMethod === "phone"
          ? tokenRequested?.phoneResetPassword
          : tokenRequested?.dataToSend.email,
    };
    resetPassword.reset();
    resetPassword.mutate(dataResetPassword, {
      onSuccess: () => {
        setShowModal(true);
      },
      onError: (err) => {
        toast.error(err?.response?.data);
      },
    });
  };

  return (
    <PageWrapper>
      <ModalSuccess showModal={showModal} handleCloseModal={handleCloseModal} />
      {!isMobile && (
        <img
          src={mobile}
          alt="image mobile"
          width={"669px"}
          height={"417px"}
          style={{ position: "absolute", left: "0", bottom: "0", zIndex: -1 }}
        />
      )}
      <Card
        align="center"
        p={
          !isMobile
            ? "41.44px 43px 30px 43px"
            : "36.51px 24.66px 38.5px 22.66px"
        }
        style={{ zIndex: 1 }}
        m="89px 0 86px 0"
        width={!isMobile ? "394px" : "326px"}
      >
        <img src={pulpey} alt="pulpey" width={"59.56px"} height={"51.06px"} />
        <Text
          mt="15px"
          mb="14.5px"
          size={theme.fonts.size.h3}
          color={theme.colors.white}
        >
          Cambia tu contraseña
        </Text>
        <Text size={theme.fonts.size.h6} color={theme.colors.gray200} mb="24px">
          Tu nueva contraseña debe tener:
        </Text>
        <Col align="left" m=" 0 0 30px 0" gap="5px">
          {passwordInfo.map((item, index) => (
            <Row gap="11px">
              {statusPassword[item.key] ? (
                <Div
                  key={index}
                  radius="50px"
                  border={`1px solid ${theme.colors.red}`}
                  background={theme.colors.red}
                  style={{ minWidth: "14px" }}
                  height="14px"
                  align="center"
                  justify="center"
                >
                  <CheckIcon stroke={theme.colors.white} />
                </Div>
              ) : (
                <Div
                  key={index}
                  radius="50px"
                  border={`1px solid ${theme.colors.red}`}
                  style={{ minWidth: "14px" }}
                  height="14px"
                />
              )}
              <Text color={theme.colors.white} size={theme.fonts.size.sm}>
                {item.name}
              </Text>
            </Row>
          ))}
        </Col>
        <form onSubmit={handleSubmit(handleResetPasword)}>
          <Col gap="16px" m="0 0 24px 0">
            <Col>
              <Controller
                name="password"
                control={control}
                render={({ field }) => (
                  <Password
                    placeholder={"Nueva contraseña"}
                    error={errors.password}
                    helper={errors.password ? errors.password.message : ""}
                    {...field}
                  />
                )}
              />
            </Col>
            <Controller
              name="confirmPassword"
              control={control}
              render={({ field }) => (
                <Password
                  placeholder={"Confirmar contraseña"}
                  error={errors.confirmPassword}
                  helper={
                    errors.confirmPassword ? errors.confirmPassword.message : ""
                  }
                  {...field}
                />
              )}
            />
          </Col>

          <Button
            htmlType="submit"
            loading={resetPassword.isLoading}
            disabled={!isValid}
            width={!isMobile ? "308px" : "100%"}
          >
            Cambiar contraseña
          </Button>
          <Row justify="center" m="15px 0 0 0">
            <Text
              color={theme.colors.white}
              style={{ cursor: "pointer" }}
              onClick={() => navigate("/login")}
            >
              Cancelar
            </Text>
          </Row>
        </form>
      </Card>
      <CopywriteFooter />
    </PageWrapper>
  );
};
