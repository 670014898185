import { useMutation } from "react-query";
import { axiosClient } from "../../../core/services/axiosInstance";

const useSendEmailOtp = () => {
  const mutation = useMutation(({ email, userName, isPasswordReset }) => {
    const requestBody = { email, userName, isPasswordReset };
    return axiosClient.post("Authentication/SendEmailOTP", requestBody);
  });
  return mutation;
};
export default useSendEmailOtp;
