import React from "react";
import PropTypes from "prop-types";

import { StyledInputGroup } from "./styled";

const InputGroup = ({ proportion, children, ...rest }) => {
  return (
    <StyledInputGroup proportion={proportion} {...rest}>
      {children}
    </StyledInputGroup>
  );
};

InputGroup.defaultProps = {
  proportion: "50% 50%",
};

export default InputGroup;
