import styled, { css } from "styled-components";
import { theme } from "../../../styles/theme";

export const Wrapper = styled.div`
  margin: ${(props) => props.margin};
  padding: ${(props) => props.padding};
  text-align: ${(props) => props.align || "-webkit-center"};
  ${(props) =>
    props.hasBorder &&
    css`
      border-bottom: 1px solid ${props.theme.colors.gray500};
    `}
  h4 {
    margin-bottom: 0;
  }
`;

export const WrapperIcon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 86px;
  height: 86px;
  border-radius: 100%;
  border: 1px solid;
  border-color: ${({ iconBorderColor }) =>
    iconBorderColor || theme.colors.green300};
  opacity: 1;
  margin-bottom: 10px;
`;
