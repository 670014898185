import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Modal } from "../../../components/Modal";
import { Text } from "../../../styles/Common";
import { theme } from "../../../styles/theme";
import { Button } from "../../../components/Button";
import { ConfettiIcon } from "../../../assets/icons";
import { useNavigate } from "react-router-dom";
import useAuth from "../../Login/hooks/useAuth";
import toast from "react-hot-toast";
import { setLocalUser } from "../../../core/services/axiosInstance";

const ModalSuccess = ({ showModal, handleCloseModal, dataLogin, isMobile }) => {
  const navigate = useNavigate();
  const authentication = useAuth();

  const handleContinue = () => {
    authentication.reset();
    authentication.mutate(
      {
        email: dataLogin.email,
        password: dataLogin.password,
      },
      {
        onSuccess: (res) => {
          setLocalUser(res?.data?.token);
          navigate("/profileInfo");
        },
        onError: (err) => {
          toast.error(err?.response?.data);
        },
      }
    );
  };

  return (
    <Modal
      open={showModal}
      onCancel={handleCloseModal}
      padding={"29px 63px 37px 63px"}
      width={!isMobile ? "611px" : "100%"}
      height={"auto"}
    >
      <Modal.Header
        title={!isMobile ? "¡Felicidades!" : "¡Tu registro ha sido exitoso!"}
        sizeTitle={!isMobile ? theme.fonts.size.h1 : theme.fonts.size.h3}
        colorTitle={theme.colors.blue500}
        weightTitle={theme.fonts.weight.bold}
        margin={"0 0 22px 0"}
        icon={<ConfettiIcon stroke={theme.colors.green300} />}
        iconBackground={theme.colors.white300}
      />
      <Modal.Body>
        <Text
          size={theme.fonts.size.h6}
          color={theme.colors.gray200}
          weight={theme.fonts.weight.semibold}
          mb="40px"
        >
          Ya eres parte de nuestra comunidad. Para incrementar las
          probabilidades de recibir la{" "}
          <span style={{ color: theme.colors.red }}>Pulpeybox</span>, por favor
          responde las siguientes preguntas.
        </Text>

        <Button
          width={!isMobile ? "297px" : "157px"}
          onClick={handleContinue}
          loading={authentication.isLoading}
        >
          {!isMobile ? "Continuar con las preguntas" : "Continuar"}
        </Button>
      </Modal.Body>
    </Modal>
  );
};
ModalSuccess.propTypes = {
  showModal: PropTypes.bool,
  handleCloseModal: PropTypes.func,
  dataLogin: PropTypes.object,
  isMobile: PropTypes.bool,
};
export default ModalSuccess;
