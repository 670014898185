import React, { useState, useEffect } from "react";
import { Modal } from "../../../components/Modal";
import { Text } from "../../../styles/Common";
import { theme } from "../../../styles/theme";
import { Button } from "../../../components/Button";
import { LikeIcon } from "../../../assets/icons";
import { useOutletContext } from "react-router-dom";

import { useNavigate } from "react-router-dom";

const ModalSuccess = ({ showModal, handleCloseModal }) => {
  const navigate = useNavigate();
  const { isMobile } = useOutletContext();

  const [timeLeft, setTimeLeft] = useState(300);

  useEffect(() => {
    if (timeLeft > 0) {
      const timer = setTimeout(() => setTimeLeft(timeLeft - 1), 1000);
      return () => clearTimeout(timer);
    }
  }, [timeLeft]);

  const handleContinue = () => {
    navigate("/login");
  };
  return (
    <Modal
      open={showModal}
      onCancel={handleCloseModal}
      padding={"29px 63px 37px 63px"}
      width={!isMobile ? "499px" : "100%"}
      height={!isMobile ? "372px" : "auto"}
    >
      <Modal.Header
        title={"¡Cambio exitoso!"}
        sizeTitle={theme.fonts.size.h1}
        colorTitle={theme.colors.blue500}
        weightTitle={theme.fonts.weight.bold}
        margin={"0 0 9px 0"}
        icon={<LikeIcon stroke={theme.colors.green300} />}
        iconBackground={theme.colors.white300}
      />
      <Modal.Body>
        <Text size={theme.fonts.size.h4} color={theme.colors.gray300} mb="27px">
          Tu contraseña se ha cambiado exitosamente
        </Text>

        <Button width={"157px"} onClick={handleContinue}>
          Ingresar
        </Button>
      </Modal.Body>
    </Modal>
  );
};

export default ModalSuccess;
