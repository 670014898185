import { useMutation } from "react-query";
import { axiosClient } from "../../../core/services/axiosInstance";

const useValidateAccount = () => {
  const mutation = useMutation((email) => {
    return axiosClient.post("User/ValidateAccount", email);
  });
  return mutation;
};
export default useValidateAccount;
