import { useQuery } from "react-query";
import { axiosClient } from "../services/axiosInstance";

const useGetMaritalStatus = () => {
  const data = useQuery(["getMaritalStatus"], () =>
    axiosClient.post(`Setting/GepSettingList?groupSettingId=2`)
  );
  return data;
};
export default useGetMaritalStatus;
