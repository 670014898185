import React from "react";
import PropTypes from "prop-types";
import { Drawer } from "../../../components/Drawer";
import { Div, Row, Text } from "../../../styles/Common";
import { theme } from "../../../styles/theme";
import { ChevronRightIcon, CloseIcon } from "../../../assets/icons";
import { useNavigate } from "react-router-dom";

export const DrawerMobile = ({ showDrawer, handleCloseDrawer }) => {
  const navigate = useNavigate();

  const menu = [
    {
      title: "Inicio",
      onClick: () => {
        navigate("/");
        handleCloseDrawer();
      },
    },
    {
      title: "Corporativo",
      onClick: () => {
        navigate("/insights");
        handleCloseDrawer();
      },
    },
    {
      title: "Login",
      onClick: () => {
        navigate("/login");
        handleCloseDrawer();
      },
    },
  ];

  return (
    <Drawer
      closeIcon={<CloseIcon stroke={theme.colors.white} />}
      open={showDrawer}
      onClose={handleCloseDrawer}
      width="300px"
      placement="left"
      title={
        <Text
          weight={theme.fonts.weight.semibold}
          size={theme.fonts.size.default}
          color={theme.colors.white}
        >
          Menu
        </Text>
      }
    >
      <Div direction="column" height="100%">
        <Text
          weight={theme.fonts.weight.bold}
          size={theme.fonts.size.h4}
          color={theme.colors.white}
          mb="60px"
        >
          Hola
        </Text>
        {menu.map((item, index) => (
          <Row
            key={index}
            height="46px"
            p="0 0 22px 0"
            m="0 0 20px 0"
            justify="space-between"
            style={{ borderBottom: `1px solid ${theme.colors.gray200}` }}
            onClick={item.onClick}
          >
            <Text size={theme.fonts.size.h6} color={theme.colors.white}>
              {item.title}
            </Text>
            <ChevronRightIcon stroke={theme.colors.white} />
          </Row>
        ))}
      </Div>
    </Drawer>
  );
};

DrawerMobile.propTypes = {
  showDrawer: PropTypes.bool,
  handleCloseDrawer: PropTypes.func,
};
