import styled from "styled-components";
import backImage from "../../assets/images/backgroundInsights.png";

export const PageWrapper = styled.div`
  display: flex;
  min-height: 100vh;
  width: 100%;
  background-position: center;
  flex-direction: column;
  align-items: center;
  padding: 0 25px;
  background: ${(props) =>
    props.isMobile &&
    `transparent  url(${backImage}) 0% 0% no-repeat padding-box`};
  background-size: ${(props) => props.isMobile && "cover"};
`;
