import React from "react";
import PropTypes from "prop-types";
import { Modal } from "../../../components/Modal";
import { Text } from "../../../styles/Common";
import { theme } from "../../../styles/theme";
import { Button } from "../../../components/Button";
import { AlertIcon } from "../../../assets/icons";
import { useNavigate } from "react-router-dom";

const ModalVerificationUserPending = ({
  showModal,
  handleCloseModal,
  isMobile,
}) => {
  const navigate = useNavigate();

  return (
    <Modal
      open={showModal}
      onCancel={handleCloseModal}
      padding={!isMobile ? "29px 63px 37px 63px" : "29px 20px 37px 20px"}
      width={!isMobile ? "550px" : "100%"}
      height={"auto"}
    >
      <Modal.Header
        title={"¡Atención!"}
        sizeTitle={theme.fonts.size.h3}
        colorTitle={theme.colors.blue500}
        weightTitle={theme.fonts.weight.bold}
        margin={"0 0 22px 0"}
        icon={<AlertIcon stroke={theme.colors.red} />}
        iconBackground={theme.colors.white50}
        iconBorderColor={theme.colors.red}
      />
      <Modal.Body>
        <Text
          size={theme.fonts.size.h6}
          color={theme.colors.gray200}
          weight={theme.fonts.weight.semibold}
          mb="40px"
        >
          Parece que ya has iniciado el proceso de registro en Pulpey, pero aún
          no lo has completado. Por favor, inicia sesión con tu cuenta para
          continuar con la validación o completar la información de tu perfil y
          así finalizar tu registro.
        </Text>

        <Button width="157px" onClick={() => navigate("/login")}>
          Iniciar sesión
        </Button>
      </Modal.Body>
    </Modal>
  );
};
ModalVerificationUserPending.propTypes = {
  showModalVerificationUserPending: PropTypes.bool,
  handleCloseModal: PropTypes.func,
  isMobile: PropTypes.bool,
};
export default ModalVerificationUserPending;
