import React from "react";
import { Text } from "../../styles/Common";
import { CustomInputPassword } from "./styles";
import { theme } from "../../styles/theme";
import { passwordLength } from "../../utilities/helpers";
import { EyeIcon, EyeCloseIcon } from "../../assets/icons";

export const Password = ({
  placeholder,
  suffix,
  helper,
  error,
  disabled,
  onChange,
  onPressEnter,
  width,
  label,
  sizeLabel,
  weightLabel,
  autoComplete,
  colorIcon,
  ...rest
}) => {
  return (
    <div style={{ width }}>
      <Text
        weight={weightLabel}
        size={sizeLabel || theme.fonts.size.sm}
        color={theme.colors.gray500}
        mt="0px"
        mb="7px"
        ml="6px"
        align="left"
      >
        {label}
      </Text>
      <CustomInputPassword
        placeholder={placeholder}
        suffix={suffix}
        status={error ? "error" : ""}
        disabled={disabled}
        onChange={onChange}
        onPressEnter={onPressEnter}
        maxLength={passwordLength}
        autoComplete={autoComplete}
        iconRender={(visible) =>
          visible ? (
            <EyeIcon stroke={colorIcon || theme.colors.gray200} />
          ) : (
            <EyeCloseIcon stroke={colorIcon || theme.colors.gray200} />
          )
        }
        {...rest}
      />
      {helper && (
        <Text size={theme.fonts.size.xs} color={theme.colors.red} mt="2px">
          {helper}
        </Text>
      )}
    </div>
  );
};
