import React, { useState, useEffect } from "react";
import { PageWrapper } from "./styles";
import { yupResolver } from "@hookform/resolvers/yup";
import { Div, Text, Row } from "../../styles/Common";
import { theme } from "../../styles/theme";
import { Button } from "../../components/Button";
import logo from "../../assets/images/logo.svg";
import { useNavigate } from "react-router-dom";
import { CopywriteFooter } from "../../components/CopywriteFooter";
import { Card } from "../../components/Card";
import { Input } from "../../components/Input";
import { Select } from "../../components/Select";
import { useForm, Controller } from "react-hook-form";
import Checkbox from "../../components/Checkbox";
import cellPhone from "../../assets/images/Group 102@2x.png";
import { validateContactUs } from "../../utilities/validations";
import useGetPhoneCode from "../../core/hooks/useGetPhoneCode";
import { TextArea } from "../../components/TextArea";
import useCreateContactUs from "./hooks/useCreateContactUs";
import toast from "react-hot-toast";
import { useOutletContext } from "react-router-dom";
import { MenuOutlined } from "@ant-design/icons";
import { DrawerMobile } from "./components/DrawerMobile";

export const ContactUs = () => {
  const { isMobile } = useOutletContext();

  const {
    control,
    handleSubmit,
    watch,
    reset,
    formState: { errors },
  } = useForm({
    mode: "onBlur",
    resolver: yupResolver(validateContactUs),
    defaultValues: {
      ind: 1,
    },
  });

  const navigate = useNavigate();
  const createContactUs = useCreateContactUs();

  const [acceptTerms, setAcceptTerms] = useState(false);
  const [isCompletedForm, setIsCompletedForm] = useState(false);
  const [showDrawer, setShowDrawer] = useState(false);
  const [activeKey, setActiveKey] = useState("2");

  const getPhoneCode = useGetPhoneCode();
  const { data, isSuccess } = getPhoneCode;
  const [optionsCodes, setOptionsCodes] = useState([]);
  useEffect(() => {
    if (isSuccess) {
      setOptionsCodes(
        data?.data?.result.map((item) => ({
          label: item.code,
          value: item.id,
        }))
      );
    }
  }, [data, isSuccess]);

  const watchedFields = watch([
    "name",
    "lastname",
    "email",
    "ind",
    "phone",
    "company",
    "message",
  ]);

  useEffect(() => {
    const fieldsToCheck = watchedFields.filter(
      (_, index) => index !== 6 && index !== 5
    );
    const isCompleted = fieldsToCheck.every(
      (item) => item !== "" && item !== undefined && item !== null
    );
    setIsCompletedForm(isCompleted);
  }, [watchedFields]);

  const handleCloseDrawer = () => {
    setShowDrawer(false);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleContact = (data) => {
    createContactUs.reset();
    createContactUs.mutate(
      {
        name: data?.name,
        lastName: data?.lastname,
        email: data?.email,
        codeNumberId: Number(data?.ind),
        phoneNumber: data?.phone,
        company: data?.company,
        message: data?.message ? data?.message : "No proporcionado",
        acceptTermCondition: new Date(),
      },
      {
        onSuccess: () => {
          toast.success("Gracias, ¡Pronto nos pondremos en contacto!");
          reset({
            ind: 1,
          });
          setAcceptTerms(false);
        },
        onError: (err) => {
          toast.error(
            err?.response?.data?.errors?.Company || err?.response?.data?.errors
          );
        },
      }
    );
  };

  return (
    <PageWrapper isMobile={isMobile}>
      <DrawerMobile
        showDrawer={showDrawer}
        handleCloseDrawer={handleCloseDrawer}
      />
      <Div
        width={"100%"}
        height={"99px"}
        justify="space-between"
        p={!isMobile ? "8px 98px" : "8px 0"}
        align="center"
        m="0 0 20px 0"
        style={{ backdropFilter: "blur(16px)" }}
        gap={isMobile && "5px"}
      >
        {isMobile && (
          <MenuOutlined
            style={{ color: theme.colors.gray200, fontSize: "30px" }}
            onClick={() => setShowDrawer(true)}
          />
        )}
        <img
          src={logo}
          alt="logo"
          width={!isMobile ? "154.65px" : "100px"}
          height={"35.33px"}
          onClick={() => navigate("/")}
          style={{ cursor: "pointer" }}
        />
        {!isMobile && (
          <Div gap={!isMobile ? "18px" : "5px"} align="center">
            <Button
              variant={"outlined"}
              width={!isMobile ? "157px" : "100px"}
              height={"40px"}
              onClick={() => navigate("/")}
            >
              Inicio
            </Button>
            {!isMobile && (
              <Button
                width={!isMobile ? "157px" : "100px"}
                height={"40px"}
                variant={"outlined"}
                onClick={() => navigate("/insights")}
              >
                Corporativo
              </Button>
            )}
            <Button
              width={!isMobile ? "157px" : "100px"}
              height={"40px"}
              onClick={() => navigate("/login")}
            >
              {!isMobile ? "Login / Registro" : "Login"}
            </Button>
          </Div>
        )}
      </Div>
      <Div
        direction="column"
        m={!isMobile ? "0 0 68px 20px" : "0 0 24px 0"}
        align="left"
        width="100%"
      >
        <Text
          size={theme.fonts.size.h1i}
          color={theme.colors.white}
          weight={theme.fonts.weight.semibold}
          mb="19px"
        >
          Queremos saber de ti
        </Text>
        <Text
          size={theme.fonts.size.h4}
          color={theme.colors.white}
          style={{ width: !isMobile ? "800px" : "100%" }}
        >
          Si quieres más información acerca de productos o servicios, déjanos
          tus datos o envíanos un mensaje. Nos encantará conocerte!
        </Text>
      </Div>
      <Div
        m={!isMobile && "0 0 0 20px"}
        align={"left"}
        justify={isMobile && "center"}
        width="100%"
      >
        <Card
          width={!isMobile ? "1171px" : "326px"}
          height={"auto"}
          p={!isMobile ? "43.78px 0px 41px 39px" : "26px 25px"}
          m={!isMobile ? " 0 0 62px 0" : "0 0 24px 0"}
        >
          <Div gap="15px" direction={!isMobile ? "row" : "column"}>
            <Controller
              name="name"
              control={control}
              render={({ field }) => (
                <Input
                  placeholder={"Nombre"}
                  width={!isMobile ? "201px" : "100%"}
                  error={errors.name}
                  helper={errors.name ? errors.name.message : ""}
                  {...field}
                />
              )}
            />
            <Controller
              name="lastname"
              control={control}
              render={({ field }) => (
                <Input
                  placeholder={"Apellidos"}
                  width={!isMobile ? "201px" : "100%"}
                  error={errors.lastname}
                  helper={errors.lastname ? errors.lastname.message : ""}
                  {...field}
                />
              )}
            />
          </Div>
          <Controller
            name="email"
            control={control}
            render={({ field }) => (
              <Input
                placeholder={"Correo electrónico"}
                width={!isMobile ? "417px" : "100%"}
                m="24px 0 0  0"
                error={errors.email}
                helper={errors.email ? errors.email.message : ""}
                {...field}
                onChange={(e) => {
                  field.onChange(e.target.value.toLowerCase());
                }}
              />
            )}
          />
          <Row gap="15px" m="24px 0 0  0">
            <Controller
              name="ind"
              control={control}
              render={({ field }) => (
                <Select
                  placeholder="+502"
                  color={theme.colors.gray200}
                  options={optionsCodes}
                  border={theme.colors.white200}
                  width="79px"
                  height="45px"
                  style={{ padding: "0px 0px" }}
                  background={theme.colors.blue100}
                  error={errors?.ind}
                  helper={errors?.ind?.message}
                  {...field}
                />
              )}
            />
            <Controller
              name="phone"
              control={control}
              render={({ field }) => (
                <Input
                  placeholder={"Número de teléfono"}
                  width={!isMobile ? "323.5px" : "100%"}
                  error={errors.phone}
                  helper={errors.phone ? errors.phone.message : ""}
                  type="number"
                  {...field}
                />
              )}
            />
          </Row>
          <Controller
            name="company"
            control={control}
            render={({ field }) => (
              <Input
                placeholder={"Empresa"}
                width={!isMobile ? "417px" : "100%"}
                m="24px 0 0 0"
                error={errors.company}
                helper={errors.company ? errors.company.message : ""}
                {...field}
              />
            )}
          />
          <Controller
            name="message"
            control={control}
            render={({ field }) => (
              <TextArea
                placeholder={"Mensaje..."}
                width={!isMobile ? "417.5px" : "100%"}
                style={{ minHeight: "167px" }}
                m="24px 0 0 0"
                error={errors.message}
                helper={errors.message ? errors.message.message : ""}
                {...field}
              />
            )}
          />
          <Row m="21px 0 24.3px 0" gap="14px" align="center">
            <Checkbox
              checked={acceptTerms}
              onChange={() => setAcceptTerms(!acceptTerms)}
            />
            <Text color={theme.colors.white}>
              Aceptar{" "}
              <span
                style={{ color: theme.colors.red, cursor: "pointer" }}
                onClick={() =>
                  window.open(
                    `${process.env.PUBLIC_URL}/TerminosYCondiciones.pdf`,
                    "_blank"
                  )
                }
              >
                términos y condiciones
              </span>
            </Text>
          </Row>
          <form
            onSubmit={handleSubmit(handleContact)}
            style={{ justifySelf: isMobile && "center" }}
          >
            <Button
              width={"157px"}
              htmlType="submit"
              disabled={!acceptTerms || !isCompletedForm}
              loading={createContactUs.isLoading}
            >
              Enviar
            </Button>
          </form>{" "}
        </Card>

        {!isMobile && (
          <img
            src={cellPhone}
            alt="cellphone"
            width="720"
            height="572"
            style={{
              position: "absolute",
              top: "391px",
              right: "3%",
              zIndex: 1,
            }}
          />
        )}
      </Div>

      <CopywriteFooter />
    </PageWrapper>
  );
};
