import styled from "styled-components";
import backImage from "../../assets/images/backgroundHome.png";

export const PageWrapper = styled.div`
  display: flex;
  min-height: 100vh;
  width: 100%;
  background: url(${backImage}) no-repeat;
  background-position: center;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0 0px;
`;

export const PageWrapperPlatform = styled.div`
  display: flex;
  min-height: 100vh;
  width: 100%;
  background: transparent url(${backImage}) 0% 0% no-repeat padding-box;
  background-attachment: fixed;
  background-position: center;
  background-size: cover;
  flex-direction: column;
  align-items: center;
  padding: ${({ p }) => p || "0px 0px 0px 0px"};
`;
