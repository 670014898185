import React from "react";
import { CustomButton } from "./styles";
import { theme } from "../../styles/theme";

export const Button = ({
  children,
  width,
  background,
  color,
  border,
  onClick,
  weight,
  height,
  radius,
  variant,
  backgroundHover,
  ...rest
}) => {
  return variant === "outlined" ? (
    <CustomButton
      width={width}
      background={theme.colors.pink200}
      color={color}
      border={theme.colors.red}
      onClick={onClick}
      weight={weight}
      height={height}
      radius={radius}
      backgroundHover={theme.colors.pink}
      {...rest}
    >
      {children}
    </CustomButton>
  ) : (
    <CustomButton
      width={width}
      background={background}
      color={color}
      border={border}
      onClick={onClick}
      weight={weight}
      height={height}
      radius={radius}
      backgroundHover={theme.colors.red500}
      {...rest}
    >
      {children}
    </CustomButton>
  );
};
