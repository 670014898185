import styled, { css } from "styled-components";
import { Button } from "antd";
import { theme } from "../../styles/theme";

const DISABLED_FILLED = css`
  &&[disabled],
  &&[disabled]:hover {
    cursor: not-allowed;
    color: ${({ theme }) => theme.colors.gray200};
    background-color: ${({ theme }) => theme.colors.gray300};
    border-color: transparent;s
    box-shadow: none;
  }
`;

export const CustomButton = styled(Button)`
  padding: 0;
  width: ${({ width }) => width || "306px"};
  height: ${({ height }) => height || "40px"};
  background: ${({ background }) => background || theme.colors.red} 0% 0%
    no-repeat padding-box;
  border-radius: ${({ radius }) => radius || "20px"};
  opacity: 1;
  color: ${({ color }) => color || theme.colors.white};
  font-family: ${theme.fonts.family};
  font-size: ${({ size }) => size || theme.fonts.size.default};
  font-weight: ${({ weight }) => weight || theme.fonts.weight.regular};
  border: 1px solid;
  border-color: ${({ border }) => border || "transparent"};
  margin: ${({ m }) => m || "0 0 0 0"};

  && {
    &.ant-btn-default {
      &:hover {
        background-color: ${({ backgroundHover }) =>
          backgroundHover || theme.colors.red500};
        border: 1px solid;
        color: ${({ color }) => color || theme.colors.white};
        border-color: ${({ border }) => border || "transparent"};
      }
    }
  }

  ${DISABLED_FILLED}
`;
