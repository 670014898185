import styled from "styled-components";
import { Drawer } from "antd";
import { theme } from "../../styles/theme";

export const CustomDrawer = styled(Drawer)`
  border-radius: ${({ placement }) =>
    placement === "left" ? "0px 30px 30px 0px" : "30px 0px 0px 30px"};

  .ant-drawer-content {
    background-color: ${(props) => props.background || theme.colors.blue500};
  }

  .ant-drawer-header {
    background-color: ${(props) => props.background || theme.colors.blue500};
    border: none;
  }

  .ant-drawer-body {
    background-color: ${(props) => props.background || theme.colors.blue500};
  }

  &&& .ant-drawer {
    box-shadow: 0px 5px 15px #00000017;
    opacity: 1;

    &-header {
      padding: 25px;
      border: none;
      width: 100%;
    }

    &-body {
      padding: 7px 25px 20px 25px;
    }

    &-content-wrapper {
      overflow: hidden;
    }
  }
`;
