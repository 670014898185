import React from "react";
import { Navigate } from "react-router-dom";
import { useAuth } from "../../core/hooks/useAuth";

export { PublicRoute };

function PublicRoute({ children }) {
  const { isAuth } = useAuth();
  return isAuth ? <Navigate to="/platform" /> : children;
}
