import { useQuery } from "react-query";
import { axiosClient } from "../services/axiosInstance";

const useGetPhoneCode = () => {
  const data = useQuery(["getPhoneCode"], () =>
    axiosClient.post(`Setting/GepSettingList?groupSettingId=1`)
  );
  return data;
};
export default useGetPhoneCode;
