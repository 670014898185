import { useQuery } from "react-query";
import { axiosClient } from "../services/axiosInstance";

const useGetNumberChildren = () => {
  const data = useQuery(["getNumberChildren"], () =>
    axiosClient.post(`Setting/GepSettingList?groupSettingId=7`)
  );
  return data;
};
export default useGetNumberChildren;
