import styled from "styled-components";
import { theme } from "../../styles/theme";

export const CustomCard = styled.div`
  width: ${({ width }) => width || "394px"};
  height: ${({ height }) => height || "auto"};
  padding: ${({ p }) => p || "0 0 0 0"};
  margin: ${({ m }) => m || "0 0 0 0"};
  background: ${({ background }) =>
    background || `${theme.colors.blue500} 0% 0% no-repeat padding-box`};
  border-radius: ${({ radius }) => radius || "47px"};
  opacity: 1;
`;
