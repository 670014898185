import React, { useState, useEffect } from "react";
import { useLocation, useOutletContext } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import { useDispatch } from "react-redux";
import { yupResolver } from "@hookform/resolvers/yup";
import { LoginWrapper } from "../Login/styles";
import { Div, Text, Row, Col } from "../../styles/Common";
import { theme } from "../../styles/theme";
import { Button } from "../../components/Button";
import logo from "../../assets/images/logo.svg";
import pulpey from "../../assets/images/pulpey.svg";
import { Card } from "../../components/Card";
import { Input } from "../../components/Input";
import { Password } from "../../components/Password";
import mobile from "../../assets/images/Mobile.png";
import toast from "react-hot-toast";
import { encrypt } from "../../utilities/helpers";
import { useNavigate } from "react-router-dom";
import Checkbox from "../../components/Checkbox";
import { CopywriteFooter } from "../../components/CopywriteFooter";
import useCreateUser from "./hooks/useCreateUser";
import { validateRegister } from "../../utilities/validations";
import useGetPhoneCode from "../../core/hooks/useGetPhoneCode";
import { Select } from "../../components/Select";
import ModalOTPUserRegister from "./componentes/ModalOTPUserRegister";
import { setVerificationTokens } from "../../core/store/verificationCodes";
import ModalSuccess from "./componentes/ModalSuccess";
import { CheckIcon } from "../../assets/icons";
import { getLabelById } from "../../utilities/helpers";
import { Progress } from "../../components/Progress";
import { Link } from "../../components/Link";
import ModalVerificationUserPending from "./componentes/ModalVerificationUserPending";

export const UserRegister = () => {
  const { isMobile } = useOutletContext();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const createUser = useCreateUser();

  const location = useLocation();
  const { search } = location;
  const acceptedTerms = new URLSearchParams(search).get("termsChecked");

  const [showModal, setShowModal] = useState(false);
  const [showModalOTP, setShowModalOTP] = useState(false);
  const [
    showModalVerificationUserPending,
    setShowModalVerificationUserPending,
  ] = useState(false);

  const [acceptTerms, setAcceptTerms] = useState(acceptedTerms === "true");
  const [isCompletedForm, setIsCompletedForm] = useState(false);
  const [showPasswordTooltip, setShowPasswordTooltip] = useState(false);
  const [dataLogin, setDataLogin] = useState({});

  const {
    register,
    handleSubmit,
    control,
    watch,
    formState: { errors },
  } = useForm({
    mode: "onBlur",
    resolver: yupResolver(validateRegister),
    defaultValues: {
      ind: 1,
    },
  });

  const [progress, setProgress] = useState({
    percent: 30,
    color: theme.colors.red,
  });

  const password = watch("password");

  useEffect(() => {
    const checkPasswordStrength = (pwd) => {
      if (!pwd) {
        return { percent: 30, color: theme.colors.red };
      }
      let score = 0;
      let validBasic = true;
      let hasUppercase = (pwd.match(/[A-Z]/g) || []).length;
      let hasDigits = (pwd.match(/[0-9]/g) || []).length;
      let hasSpecialChars = (pwd.match(/[.#?!@$%^&*-]/g) || []).length;
      if (pwd.length >= 8) score += 10;
      else validBasic = false;
      if (/[A-Z]/.test(pwd)) score += 10;
      else validBasic = false;
      if (/[0-9]/.test(pwd)) score += 10;
      else validBasic = false;
      if (/[#?!@$%^&*.\-]/.test(pwd)) score += 10;
      else validBasic = false;
      if (pwd.length >= 9) score += 60;
      if (
        validBasic &&
        (hasUppercase >= 2 ||
          hasDigits >= 2 ||
          hasSpecialChars >= 2 ||
          pwd.length >= 9)
      ) {
        return { percent: 100, color: theme.colors.green };
      } else if (validBasic) {
        return { percent: 70, color: theme.colors.yellow };
      } else {
        return { percent: 30, color: theme.colors.red };
      }
    };
    const result = checkPasswordStrength(password);
    setProgress(result);
  }, [password]);

  const [statusPassword, setStatusPassword] = useState({
    hasUppercase: false,
    hasNumber: false,
    hasSpecialChars: false,
    length: false,
  });

  useEffect(() => {
    const checkPasswordStrength = (pwd) => {
      if (!pwd) {
        return {
          hasUppercase: false,
          hasNumber: false,
          hasSpecialChars: false,
          length: false,
        };
      }
      return {
        length: pwd.length >= 8,
        hasUppercase: /[A-Z]/.test(pwd),
        hasNumber: /\d/.test(pwd),
        hasSpecialChars: /[#?!@$%^&*.\-]/.test(pwd),
      };
    };

    const result = checkPasswordStrength(password);
    setStatusPassword(result);
  }, [password]);

  const passwordInfo = [
    { name: "Mínimo 8 caracteres", key: "length" },
    { name: "Incluir al menos una mayúscula", key: "hasUppercase" },
    { name: "Incluir números", key: "hasNumber" },
    {
      name: "Incluir al menos un carácter especial como: .#?!@$%^&*-",
      key: "hasSpecialChars",
    },
  ];

  const getPhoneCode = useGetPhoneCode();
  const { data, isSuccess } = getPhoneCode;
  const [optionsCodes, setOptionsCodes] = useState([]);
  useEffect(() => {
    if (isSuccess) {
      setOptionsCodes(
        data?.data?.result.map((item) => ({
          label: item.code,
          value: item.id,
        }))
      );
    }
  }, [data, isSuccess]);

  const watchedFields = watch([
    "name",
    "lastname",
    "ind",
    "phone",
    "email",
    "emailConfirm",
    "password",
    "repeatPassword",
  ]);
  useEffect(() => {
    const isCompleted = watchedFields.every(
      (item) => item !== "" && item !== undefined && item !== null
    );
    setIsCompletedForm(isCompleted);
  }, [watchedFields]);

  const handleCloseModal = () => {
    setShowModalOTP(false);
    setShowModal(false);
    setShowModalVerificationUserPending(false);
  };

  const handleUserRegister = (data) => {
    createUser.reset();
    createUser.mutate(
      {
        lastName: data.lastname,
        name: data.name,
        email: data.email,
        comfirmEmail: data.emailConfirm,
        password: encrypt(data.password),
        confirmPassword: encrypt(data.repeatPassword),
        referralCode: data.code,
        mobilNumber: data.phone,
        mobilNumberCodeId: Number(data.ind),
        acceptTermCondition: new Date(),
      },
      {
        onSuccess: (res) => {
          setDataLogin({
            email: data.email,
            password: encrypt(data.password),
          });
          dispatch(
            setVerificationTokens({
              tokenEmail: res?.data?.result?.otpEmail,
              tokenPhone: res?.data?.result?.otpSms,
            })
          );
          setShowModalOTP(true);
        },
        onError: (err) => {
          if (err?.response?.data?.errors[0] === "NoAccountValidate") {
            setShowModalVerificationUserPending(true);
          } else {
            toast.error(err?.response?.data?.errors || err?.response?.data);
          }
        },
      }
    );
  };

  return (
    <LoginWrapper
      isMobile={isMobile}
      style={{ margin: isMobile && "0 0 20px 0" }}
    >
      <ModalOTPUserRegister
        showModalOTP={showModalOTP}
        handleCloseModal={handleCloseModal}
        name={watchedFields[0]}
        phone={`${getLabelById(Number(watchedFields[2]), optionsCodes)}${
          watchedFields[3]
        }`}
        email={watchedFields[4]}
        setShowModal={setShowModal}
        isMobile={isMobile}
      />
      <ModalSuccess
        showModal={showModal}
        handleCloseModal={handleCloseModal}
        dataLogin={dataLogin}
        isMobile={isMobile}
      />
      <ModalVerificationUserPending
        showModal={showModalVerificationUserPending}
        handleCloseModal={handleCloseModal}
        isMobile={isMobile}
      />
      <Row
        gap="54px"
        m="50px 0 10px 0"
        justify={!isMobile ? "space-between" : "center"}
        align="center"
      >
        {!isMobile && (
          <Div
            align="left"
            direction="column"
            width="640px"
            m="0 0 0 98px "
            gap="40.75px"
          >
            <img
              src={logo}
              alt="logo"
              width={"175.65px"}
              height={"40.13px"}
              onClick={() => navigate("/")}
              style={{ cursor: "pointer" }}
            />
            <Div
              direction="column"
              border={`1px solid ${theme.colors.gray400}`}
              radius="26px"
              p="31.22px 35px 34px 34px"
              background={theme.colors.blackBlur2}
              style={{ zIndex: 1, backdropFilter: "blur(30px)" }}
            >
              <Text
                size={theme.fonts.size.h1i}
                color={theme.colors.red}
                weight={theme.fonts.weight.semibold}
              >
                Regístrate ahora
              </Text>
              <Text
                size={theme.fonts.size.h3}
                color={theme.colors.white}
                weight={theme.fonts.weight.semibold}
                mt="23.61px"
                mb="12px"
              >
                Vive la experiencia de compartir con tus marcas favoritas hasta
                la puerta de tu casa
              </Text>

              <Text
                size={theme.fonts.size.h6}
                color={theme.colors.white}
                weight={theme.fonts.weight.semibold}
              >
                Ya tengo cuenta {""}
                <span
                  style={{
                    color: theme.colors.yellow,
                    textDecoration: "underline",
                    cursor: "pointer",
                  }}
                  onClick={() => navigate("/login")}
                >
                  Inicia sesión aquí
                </span>
              </Text>
            </Div>
            <img
              src={mobile}
              alt="image mobile"
              width={"669px"}
              height={"417px"}
              style={{ position: "absolute", left: "0", bottom: "0" }}
            />
          </Div>
        )}
        <Card
          align="center"
          p={
            !isMobile
              ? "41.78px 43.49px 23.25px 43.51px"
              : "36px 25px 42px 25px"
          }
          width={!isMobile ? "477px" : "326px"}
        >
          {isMobile && (
            <>
              <img
                src={pulpey}
                alt="pulpey"
                width={"59.56px"}
                height={"51.06px"}
              />
              <Text
                mt="16px"
                mb="24px"
                size={theme.fonts.size.h3}
                color={theme.colors.white}
              >
                Regístrate
              </Text>
            </>
          )}
          <Row gap="14.51px">
            <Controller
              name="name"
              control={control}
              render={({ field }) => (
                <Input
                  placeholder={"Nombre"}
                  width={"187px"}
                  error={errors.name}
                  helper={errors.name ? errors.name.message : ""}
                  {...field}
                />
              )}
            />
            <Controller
              name="lastname"
              control={control}
              render={({ field }) => (
                <Input
                  placeholder={"Apellidos"}
                  width={"187px"}
                  error={errors.lastname}
                  helper={errors.lastname ? errors.lastname.message : ""}
                  {...field}
                />
              )}
            />
          </Row>
          <Row gap="14.99px" m="24px 0 0  0">
            <Controller
              name="ind"
              control={control}
              render={({ field }) => (
                <Select
                  placeholder="+502"
                  color={theme.colors.gray200}
                  options={optionsCodes}
                  border={theme.colors.white200}
                  width="79px"
                  height="45px"
                  style={{ padding: "0 2px" }}
                  background={theme.colors.blue100}
                  error={errors?.ind}
                  helper={errors?.ind?.message}
                  {...field}
                />
              )}
            />
            <Controller
              name="phone"
              control={control}
              render={({ field }) => (
                <Input
                  placeholder={"Celular"}
                  width={"295px"}
                  error={errors.phone}
                  helper={errors.phone ? errors.phone.message : ""}
                  onChange={(e) => {
                    const value = e.target.value;
                    const filteredValue = value.replace(/[^0-9]/g, "");
                    field.onChange(filteredValue);
                  }}
                  value={field.value}
                />
              )}
            />
          </Row>
          <Controller
            name="email"
            control={control}
            render={({ field }) => (
              <Input
                placeholder={"Correo electrónico"}
                m="24px 0 0  0"
                error={errors.email}
                helper={errors.email ? errors.email.message : ""}
                {...field}
                onChange={(e) => {
                  field.onChange(e.target.value.toLowerCase());
                }}
              />
            )}
          />
          <Controller
            name="emailConfirm"
            control={control}
            render={({ field }) => (
              <Input
                placeholder={"Confirmar correo electrónico"}
                m="24px 0 0  0"
                error={errors.emailConfirm}
                helper={errors.emailConfirm ? errors.emailConfirm.message : ""}
                {...field}
                onChange={(e) => {
                  field.onChange(e.target.value.toLowerCase());
                }}
              />
            )}
          />
          {showPasswordTooltip && (
            <Div
              width={!isMobile ? "379px" : "326px"}
              height="185.17px"
              direction="column"
              background={theme.colors.white}
              p={!isMobile ? "24.41px 46px 19.77px 29px" : "24px"}
              radius="20px"
              style={{
                position: "absolute",
                top: !isMobile ? "184.59px" : "300px",
                right: !isMobile ? "150px" : "auto",
                left: isMobile && "50%",
                transform: isMobile ? "translateX(-50%)" : "none",
                zIndex: 1,
              }}
            >
              <Text
                color={theme.colors.blue500}
                weight={theme.fonts.weight.semibold}
                mb="24px"
                align="left"
              >
                Tu contraseña debe tener:
              </Text>
              <Col align="left" gap="5px">
                {passwordInfo.map((item, index) => (
                  <Row gap="11px">
                    {statusPassword[item.key] ? (
                      <Div
                        key={index}
                        radius="50px"
                        border={`1px solid ${theme.colors.red}`}
                        background={theme.colors.red}
                        style={{ minWidth: "14px" }}
                        height="14px"
                        align="center"
                        justify="center"
                      >
                        <CheckIcon stroke={theme.colors.white} />
                      </Div>
                    ) : (
                      <Div
                        key={index}
                        radius="50px"
                        border={`1px solid ${theme.colors.red}`}
                        style={{ minWidth: "14px" }}
                        height="14px"
                      />
                    )}
                    <Text
                      color={theme.colors.gray300}
                      size={theme.fonts.size.sm}
                    >
                      {item.name}
                    </Text>
                  </Row>
                ))}
              </Col>
            </Div>
          )}
          <Controller
            name="password"
            control={control}
            render={({ field }) => (
              <Password
                placeholder={"Contraseña"}
                {...register("password")}
                error={errors.password}
                m="24px 0 0  0"
                helper={errors.password ? errors.password.message : ""}
                onFocus={() => setShowPasswordTooltip(true)}
                {...field}
                onBlur={() => {
                  setShowPasswordTooltip(false);
                  field.onBlur();
                }}
              />
            )}
          />
          <Progress
            percent={password ? progress.percent : 0}
            height={"4px"}
            showInfo={false}
            background={progress.color}
          />
          <Controller
            name="repeatPassword"
            control={control}
            render={({ field }) => (
              <Password
                placeholder={"Repetir contraseña"}
                m="14px 0 0  0"
                {...register("password")}
                error={errors.repeatPassword}
                helper={
                  errors.repeatPassword ? errors.repeatPassword.message : ""
                }
                {...field}
              />
            )}
          />
          <Controller
            name="code"
            control={control}
            render={({ field }) => (
              <Input
                placeholder={"Código referido (opcional)"}
                m="24px 0 0  0"
                {...field}
              />
            )}
          />
          <Row
            m="29px 0 26px 0"
            gap="14px"
            align="center"
            justify={isMobile && "center"}
          >
            <Checkbox
              checked={acceptTerms}
              onChange={() => setAcceptTerms(!acceptTerms)}
            />
            <Text color={theme.colors.white}>
              Aceptar{" "}
              <span
                style={{ color: theme.colors.red, cursor: "pointer" }}
                onClick={() =>
                  window.open(
                    `${process.env.PUBLIC_URL}/TerminosYCondiciones.pdf`,
                    "_blank"
                  )
                }
              >
                términos y condiciones
              </span>
            </Text>
          </Row>
          <form onSubmit={handleSubmit(handleUserRegister)}>
            <Button
              m="0 0 28px 0"
              width={!isMobile ? "390px" : "276px"}
              htmlType="submit"
              loading={createUser.isLoading}
              disabled={!acceptTerms || !isCompletedForm}
            >
              Registrarme
            </Button>
          </form>

          {isMobile && (
            <>
              <Div
                height="1px"
                background={theme.colors.gray200}
                m="0 0 23px 0"
              />
              <Row gap="13.5px" justify="center">
                <Text size={theme.fonts.size.sm} color={theme.colors.white}>
                  ¿Ya tienes cuenta?
                </Text>
                <Link>
                  <Text
                    size={theme.fonts.size.sm}
                    color={theme.colors.red}
                    onClick={() => navigate("/login")}
                  >
                    Ingresa aquí
                  </Text>
                </Link>
              </Row>
            </>
          )}
          <Row justify="center">
            <Link>
              <Text
                size={
                  !isMobile ? theme.fonts.size.default : theme.fonts.size.sm
                }
                color={theme.colors.red}
                weight={!isMobile && theme.fonts.weight.semibold}
                onClick={() => navigate("/")}
              >
                Volver al inicio
              </Text>
            </Link>
          </Row>
        </Card>
      </Row>
      <CopywriteFooter />
    </LoginWrapper>
  );
};
