import { createSlice } from "@reduxjs/toolkit";
import { axiosClient } from "../../services/axiosInstance";

export const slice = createSlice({
  name: "auth",
  initialState: {
    tokenRequested: {},
  },
  reducers: {
    setTokenRequested: (state, action) => {
      state.tokenRequested = action.payload;
    },
  },
});

export default slice.reducer;

export const { setTokenRequested } = slice.actions;
