import styled from "styled-components";
import { Input } from "antd";
import { theme } from "../../styles/theme";

export const CustomInput = styled(Input)`
  font-family: ${theme.fonts.family};
  font-size: ${({ size }) => size || theme.fonts.size.default};
  width: ${({ width }) => width || null};
  margin: ${({ m }) => m || null};
  height: ${({ height }) => height || "45px"};
  border: 1px solid ${({ border }) => border || theme.colors.white200};
  border-radius: ${({ radius }) => radius || "23px"};
  background: ${({ background }) => background || theme.colors.blue100};
  color: ${({ color }) => color || theme.colors.gray200};
  padding: 0 15px;
  opacity: 1;

  && {
    &.ant-input,
    &.ant-input-affix-wrapper {
      color: ${({ color }) => color || theme.colors.gray200};
      font-size: 16px;

      &::placeholder {
        color: ${theme.colors.gray200};
      }

      &:focus {
        outline: 0;
        border: 1px solid ${({ border }) => border || theme.colors.white200};
        box-shadow: initial;
      }

      &:hover {
        border: 1px solid ${({ border }) => border || theme.colors.white200};
        box-shadow: initial;
      }

      &:disabled {
        /* Estilos para cuando el input está deshabilitado */
        background: ${({ background }) => background || theme.colors.white300};
        cursor: not-allowed;
        color: ${theme.colors.gray400};
        border: 1px solid ${theme.colors.white200};
      }

      &:-webkit-autofill {
        -webkit-box-shadow: 0 0 0 30px
          ${({ background }) => background || theme.colors.blue100} inset;
        box-shadow: 0 0 0 30px
          ${({ background }) => background || theme.colors.blue100} inset;
        -webkit-text-fill-color: ${({ color }) =>
          color || theme.colors.gray200};
      }
    }

    &.ant-input-affix-wrapper {
      &-focused {
        outline: 0;
        border: 1px solid ${theme.colors.white200} !important;
        box-shadow: initial;
      }

      input {
        background: ${({ background }) => background || theme.colors.blue100};
        color: ${({ color }) => color || theme.colors.gray200};
        font-family: ${theme.fonts.family};

        &::placeholder {
          color: ${theme.colors.gray200};
        }
      }
    }
  }
`;
